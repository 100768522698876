import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import { ENV } from "../../utils";
import UserService from "../../services/moduleAuth/User/User.service";

const { Text } = Typography;

function RoleSelector({ element, dataName, title, form }) {
  const [roleSelected, setRoleSelected] = useState("");
  const [posibleRoles, setPosibleRoles] = useState([]);
  const [rolesThatRequiredCouncils] = useState(["USER_COUNCIL"]);
  const [council_api] = useState(
    new BasicApi(ENV.Auth_API_LOCAL + "/" + ENV.API_ROUTES.COUNCIL),
  );
  const [user_api] = useState(new UserService());

  const [councils, setCouncils] = useState([]);

  const { t } = useTranslation();

  const onRoleChange = (value) => {
    setRoleSelected(value);
    form.setFieldValue(dataName, value);
    if (!rolesThatRequiredCouncils.includes(value))
      form.setFieldValue("councilsId", []);
  };
  const onCouncilChange = (value) => {
    form.setFieldValue("councilsId", value);
  };

  useEffect(() => {
    council_api
      .getAllWithoutPagination()
      .then((value) => setCouncils(value))
      .catch((error) => {
        console.error(error);
        setCouncils([]);
      });

    user_api
      .getAllRoles()
      .then((value) => setPosibleRoles(value))
      .catch((error) => {
        console.error(error);
        setPosibleRoles([]);
      });
  }, [council_api, user_api]);

  return (
    <>
      <Form.Item
        key={dataName}
        label={t(title)}
        name={dataName}
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={posibleRoles.map((name) => ({ label: name, value: name }))}
          onChange={onRoleChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </Form.Item>
      {rolesThatRequiredCouncils.includes(roleSelected) && (
        <Form.Item
          key={"councilsId"}
          label={"Councils"}
          name={"councilsId"}
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            showSearch
            style={{ width: 200 }}
            placeholder={t("Search to Select")}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={councils.map((council) => ({
              label: council.name,
              value: council.id,
            }))}
            onChange={onCouncilChange}
            defaultValue={element[dataName]}
          />
        </Form.Item>
      )}
    </>
  );
}

export default RoleSelector;
