import React from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { EditOutlined,DeleteOutlined, ApartmentOutlined } from "@ant-design/icons";

import { Flex, message, Space } from "antd";
import ScheduleParentModal from "../../../components/moduleStreetlight/ScheduleParentModal/ScheduleParentModal"

// utils
import { FullDynamicTable } from "../../../components/DynamicTable";
import stGroupService from "../../../services/moduleStreetlight/StGroup/StGroup.service";
import {}  from "../../../components/moduleStreetlight/ScheduleParentModal/ScheduleParentModal"
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
interface params {
    ControlCabinetId:number,
    ActuatorId:number
}
export default function StGroup({ControlCabinetId,ActuatorId}: params) {
    const navigate = useNavigate();
    const [entityId, setEntityId] = useState<number>(-1);
    const service = new stGroupService(ControlCabinetId,ActuatorId)
    const [refreshTable, setRefreshTable] = useState<boolean>(true);

    // Modals
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    
    const ModalAddCallback=()=>{
      setEntityId(-1)
      setEditModal(true)
    }  
    
    const columnsWithActions = [
      ...service.getColumns(),
      {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 100,
        align: 'center',
        render: (text:any, record:any) => (
        <Flex justify="center" ><Space align="center">
          <EditOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              setEntityId(record.id);
              setEditModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <ApartmentOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              navigate(`${record.id}`);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <DeleteOutlined
            style={{ fontSize: "1.8em", color: "red" }}
            onClick={() => {
              setEntityId(record.id);
              setDeleteModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
        </Space></Flex>
      ),
    },
  ];
  return (
    <>
      <ScheduleParentModal
        service={service}
        entityId={entityId}
        modalOpen={editModal}
        setModalOpen={setEditModal}
        scheduleParentType="StGroup"
        callback={()=>setRefreshTable(!refreshTable)}
      />
      <ConfirmationModal
        callback={async ()=>{ 
          if(await service.delete(entityId)){
            setRefreshTable(!refreshTable);
            message.success("Deleted successfully!")
          }
        }}
        message="Esta seguro de que quiere eliminar este actuador?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <FullDynamicTable
        title="Streetlight groups"
        service={service}
        columns={columnsWithActions}
        generalActions={
          { 
            add: {replaceForm:true,callback:ModalAddCallback}, 
            deleteSelected: {}
          }
        }
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        multiSelect={true}
      />
    </>
  );
}
