import { Modal } from "antd";
import { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Table } from "antd";
import StaService from "../../../../../services/moduleSTA/Sta/Sta.service";

export function ModalSaltInfo({ originalData = {}, modalOpen, setModalOpen }) {
  let [actualVersion, setActualVersion] = useState({
    title: "",
    version: "",
    salt: "",
  });
  let [shouldVersion, setShouldVersion] = useState({
    title: "",
    version: "",
    salt: "",
  });
  const [staService] = useState(new StaService());

  useEffect(() => {
    if (originalData.dev_EUI)
      staService.getSalt(originalData.dev_EUI).then((data) => {
        let tmp;
        tmp = data.result.filter(
          (item) => Number(originalData.actual) === Number(item.version),
        );
        if (tmp.length > 0) setActualVersion({ ...tmp[0], title: "actual" });
        tmp = data.result.filter(
          (item) => Number(originalData.should) === Number(item.version),
        );
        if (tmp.length > 0) setShouldVersion({ ...tmp[0], title: "should" });
      });
  }, [originalData, staService]);

  return (
    <Modal
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={() => setModalOpen(false)}
    >
      <Title>{originalData.name}</Title>
      <Table dataSource={[actualVersion, shouldVersion]} pagination={false}>
        <Table.Column
          title="version"
          dataIndex="version"
          key="version"
          render={(text, record) => `${record.version} - ${record.title}`}
        />
        <Table.Column title="value" dataIndex="salt" key="salt" />
      </Table>
    </Modal>
  );
}
