// utils
import React from "react";
import { useState } from "react";
import AddOrEditEntityModal from "../../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import { FullDynamicTable } from "../../../components/DynamicTable";
import StreetlightService from "../../../services/moduleStreetlight/Streetlight/Streetlight.service";
import { Button, Flex, message, Space } from "antd";
import { EditOutlined, DeleteOutlined,ClockCircleOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import ScheduleModal from "../../../components/moduleStreetlight/ScheduleModal/ScheduleModal";
import ScheduleButton from "../../../components/moduleStreetlight/ScheduleButton/ScheduleButton";
import { v4 as uuidV4 } from 'uuid';

export default function StreetlightTab({ControlCabinetId}: {  ControlCabinetId: number;}) {
  const [streetlightId, setStreetlightId] = useState(0);
  const [service] = useState<StreetlightService>(new StreetlightService(ControlCabinetId));
  const [refreshTable, setRefreshTable] = useState<boolean>(true);
  const [selecetedEntities,setSelecetedEntities] = useState<number[]>([])
  
  //MODALS
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false);
  
  const columnsWithActions = [
        ...service.getColumns(),
        {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 100,
        align: 'center',
        render: (text:string, record:any) => (
          <Flex justify="center" ><Space align="center">
            <ScheduleButton
              onClick={()=>{setSelecetedEntities([record.id]);setScheduleModalOpen(true);}}
            />
          <EditOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              setStreetlightId(record.id);
              setEditModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <DeleteOutlined
            style={{ fontSize: "1.8em", color: "red" }}
            onClick={() => {
              setStreetlightId(record.id);
              setDeleteModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
        </Space></Flex>
      ),
    },
  ];
  return (
    <>
      {/* Streetlight*/}

      <AddOrEditEntityModal
        title="Streetlight"
        type="edit"
        service={service}
        id={streetlightId}
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        paramsPerPage={10}
        callback={() => {
          setRefreshTable(!refreshTable);
        }}
      />
      <ScheduleModal
        entitiesIds={selecetedEntities}
        modalOpen={scheduleModalOpen}
        setModalOpen={setScheduleModalOpen}
        columns= {[
          {title: 'Inicio',dataIndex: 'startAt',type:"amanecerOcaso",dateFormat:"HH:mm"},
          {title: 'Intensidad',dataIndex: 'intensity',type:"number"},
        ]}
        dataSource={[
          {
            days: ["Lunes","Martes","Miercoles","Jueves","Viernes"],
            data:[
              {key: uuidV4(),startAt:  "00:00",intensity: 100},
              {key: uuidV4(),startAt:  "00:00",intensity: 100},
            ]
          },
          {
            days: ["Sabado","Domingo"],
            data:[
              {key: uuidV4(),startAt:  "00:00",intensity: 100},
              {key: uuidV4(),startAt:  "00:00",intensity: 100},
            ]
          }
        ]}
      />
      <ConfirmationModal
        callback={async ()=>{ 
          if(await service.delete(streetlightId)){
            setRefreshTable(!refreshTable);
            message.success("Deleted successfully!")
          }
        }}
        message="Are you sure you want to delete this streetlight?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <FullDynamicTable
        title="Streetlight"
        service={service}
        columns={columnsWithActions}
        generalActions={
          { 
            add: {}, 
            configColumns: {}, 
            deleteSelected: {}, 
            csv: { 
              service: service,
              callback: () => {setRefreshTable(!refreshTable)},
              description: "'ID PLATAFORMA', 'ID device',	'TIPO', 'LOCALIZACIÓN'",
              children: () => {return (<></>)},
              options: null,
              fileExtension: ".csv" 
            }
          }
        }
        extraGeneralActions={ <Button
          style={{ margin: "6px", color: "white" }}
          className="buttonCentered"
          icon={
            <ClockCircleOutlined
              onPointerOverCapture={undefined}
              onPointerMoveCapture={undefined}
            />
          }
          onClick={() => {setScheduleModalOpen(true)}}
        />
        }
        callbackSelected={(entities:any)=>{
          setSelecetedEntities(entities)
        }}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        multiSelect={true}
      />
    </>
  );
}
