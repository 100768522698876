import { useState, useRef, useEffect } from "react";

import {
  Space,
  Table,
  theme,
  Tooltip,
  Button,
  DatePicker,
  Input,
  Spin,
  message,
  Pagination,
} from "antd";
import {
  SettingOutlined,
  LinkOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
  CalendarOutlined,
} from "@ant-design/icons";
import RemoveButton from "./RemoveButton";
import EditButton from "./EditButton";
import RedirectButton from "../RedirectButton";
import LogsButton from "./LogsButton";
import MqttLogButton from "./MqttLogButton";
import ServerButton from "./ServerButton";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LockButton from "./LockButton";
import HistoryListButton from "./HistoryListButton";
import Highlighter from "react-highlight-words";
import XmlButton from "./XmlButton";
import EditTermsButton from "./EditTermsButton";
import {
  useTableAttributes,
  useTableAttributesUpdate,
  useTableData,
  useTableDataUpdate,
} from "../../Hooks/useTableData";
import ContentPageHeader from "../ContentPageHeader";
import { ENV } from "../../utils";

import "./CommonTable.component.css";
import { BasicApi } from "../../services/general/basicAPI/basicApi";

import CNOButton from "../CNOButton/index.jsx";
import KeysButton from "./KeysButton/index.jsx";
import AuthService, {
  posibleRoles,
} from "../../services/moduleAuth/Auth/Auth.service.tsx";

const { useToken } = theme;

/**
 * Creates a Table component with variable data and columns
 * @param {*} { allAttributes, additionalInput, form, submitButton, dataName, additionalButtons, title, route }
 * @returns
 */
const CommonTable = ({
  allAttributes,
  additionalInput,
  form,
  submitButton,
  dataName,
  additionalButtons,
  title,
  route,
  extra,
  wrapper,
  showColor,
  updateAndDeleteRoute,
  CRUDPrivilages,
  canUploadCsv = false,
  csvOptions = {},
  apiBasedSearch = false,
  setApplicationChange = undefined,
  applicationChange = undefined,
  canAdd = true,
}) => {
  const [elementRemoved, setElementRemoved] = useState(false);

  const ComponentExtra = extra;
  const { token } = useToken();
  const { t } = useTranslation();
  // ---- Table data Hook
  const tableData = useTableData();
  const tableDataUpdate = useTableDataUpdate();
  const attributes = useTableAttributes();
  const setAttributes = useTableAttributesUpdate();
  // ----
  const defaultAttributes = allAttributes.filter((e) => e.default === true);
  const location = useLocation();

  const [columns, setColumns] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [reloadState, setReloadState] = useState(false);
  const [pagination, setPagination] = useState({
    defaultCurrent: 1,
    pageSize: 5,
    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [searchFilters, setSearchFilters] = useState();

  const handleReloadOnRemove = () => {
    // Actualizar el estado o realizar cualquier acción necesaria
    setElementRemoved(true);
  };
  const gatewayMac = location.pathname.split("/")[4];

  if (
    [
      ENV.API_ROUTES.ORGANIZATIONS,
      ENV.API_ROUTES.SERVICE_PROFILE,
      ENV.API_ROUTES.APPLICATIONS,
      ENV.API_ROUTES.DEVICE_PROFILE,
    ].includes(dataName)
  ) {
    route = `${ENV.Infraestructure_API}/${gatewayMac}/${dataName}`;
  }
  // Update attributes on changed location
  let api = new BasicApi(route);

  const getData = async (limit, offset, extra) => {
    try {
      setIsTableLoading(true);
      const rawData = await api.getAll(limit, offset, extra);
      if (rawData.db.result !== undefined)
        setPagination((prev) => ({ ...prev, total: rawData.db.totalCount }));
      const data = rawData.db.result;
      let dataFiltered;
      // TODO: This have to be refactored.
      // Recomended create a extern API component and this one must be a commonTable Prop
      try {
        const organizationSelected = location.pathname.split("/")[6];
        dataFiltered = data.filter(
          (item) =>
            item.topic === gatewayMac && item.organizationID === organizationSelected
        );
      } catch (error) {
        console.log("🟥 No organization selected");
      }

      if (title === "Organizations")
        dataFiltered = data.filter((item) => item.topic === gatewayMac);

      if (["Sensors", "Physical Gateways", "Centralized"].includes(title)) {
        dataFiltered = data.filter((item) => item.topic === gatewayMac);
        dataFiltered = data.map((item) => {
          const deviceLastSeen = new Date(item.lastSeen);
          const actualDate = new Date();
          const difInHours = (actualDate - deviceLastSeen) / (1000 * 60 * 60);
          //item.lastSeenDate = item.lastSeen
          const onOff = difInHours < 6 ? "🟩 ON" : "🟥 OFF";
          item.lastSeen = `${onOff}, ${item.lastSeen}`;
          return item;
        });
      }
      if (title === "Gateway") {
        dataFiltered = data.filter((item) => item.topic === gatewayMac);
        dataFiltered = data.map((item) => {
          const deviceLastSeen = new Date(item.lastSeen);
          const actualDate = new Date();
          const difInHours = (actualDate - deviceLastSeen) / (1000 * 60 * 60);
          item.lastSeenDate = item.lastSeen;
          item.lastSeen = difInHours < 6 ? "🟩 ON" : "🟥 OFF";
          return item;
        });
      }

      tableDataUpdate(dataFiltered);
      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      console.log(dataName + " table not loaded 🟥", error);
      messageApi.open({
        type: "error",
        content: t("Error while loading. "),
      });
    }
    setElementRemoved(false);
  };
  useEffect(() => {
    //setLoading(true);

    if (route || elementRemoved) {
      setPagination((prev) => ({ ...prev, current: 1 }));
      getData(pagination.pageSize, 0);
    }
    if (defaultAttributes) setAttributes(defaultAttributes);
  }, [location, reloadState]);

  useEffect(() => {
    if (title === "Sensors" && applicationChange !== undefined)
      getData(5, 0, {
        applicationID: applicationChange?.map((data) => data?.id),
      });
  }, [applicationChange]);

  // Calculate columns whenever attributes shown change
  useEffect(() => {
    setColumns(calculateColumns());
    //setLoading(false);
  }, [attributes, tableData]);

  const onSearch = async (filters) => {
    if (apiBasedSearch) {
      await getData(5, 0, filters);
    }
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    // setPagination(pagination);
    setSearchFilters(filters);
    onSearch(filters);
  };

  // ----------------------------------------------------------------------
  // DATE PICKER
  // ----------------------------------------------------------------------
  const getDaysArray = function (start, end) {
    let arr = [];
    for (let dt = new Date(start);dt <= new Date(end);dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const getColumnDateFilterProps = () => ({
    filterDropdown: ({setSelectedKeys,selectedKeys,confirm,clearFilters,close,}) => (
      <>
        <DatePicker.RangePicker
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={(value) => {
            clearFilters();
            let dates = getDaysArray(value[0]["$d"], value[1]["$d"]);
            setSelectedKeys(dates);
          }}
          onOk={(value) => {
            let dates = getDaysArray(value[0]["$d"], value[1]["$d"]);
            setSelectedKeys(dates);
          }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
      </>
    ),
    filterIcon: (filtered) => <CalendarOutlined />,
    onFilter: (value, record) => {
      let dateString = `${value.getFullYear()}-${value.getMonth()}-${value.getDay()}`;
      return record.lastLock.includes(dateString);
    },
  });

  // ----------------------------------------------------------------------
  // TEXT SEARCHER
  // ----------------------------------------------------------------------
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${
            dataIndex === "lastSeen" ? "differenceHours" : dataIndex
          }`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("Search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex, false);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      );
    },
    onFilter: (value, record) => {
      if (!apiBasedSearch) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return record[dataIndex]?.toString();
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) setTimeout(() => searchInput.current?.select(), 100);
    },
    render: (text) =>
      searchedColumn !== dataIndex ? (
        text
      ) : (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
  });

  // ----------------------------------------------------------------------

  /**
   * Creates an array of columns to show as json of antd columns.
   * Must be calculated AFTER having tableData
   * @returns columns[]
   */
  function calculateColumns() {
    let temp = [];
    attributes.forEach((attr) => {
      // Action buttons will be added later in the code
      if (attr.key === "actions") return;

      if (attr.key === "web") {
        // Safe copy of tableData
        let tD = tableData;
        for (let i = 0; i < tableData.length; i++) {
          // Create the icon
          // ERROR: currently not working
          tD[i]["url"] = (
            <Tooltip title={tableData[i].web}>
              <a href={tableData[i].web} target="_blank" rel="noreferrer">
                <LinkOutlined
                  style={{ fontSize: "large", color: token.colorPrimary }}
                />
              </a>
            </Tooltip>
          );
          tableDataUpdate(tD);
        }
      }
      if (attr.key === "serversIcon" && attr["servers"]) {
        for (let i = 0; i < tableData.length; i++) {
          let tD = tableData;
          tD[i].serversIcon = (
            <Tooltip title={t("Servers")}>
              <ServerButton element={tableData[i].servers} />
            </Tooltip>
          );
          tableDataUpdate(tD);
        }
      }
      if (attr.key === "statusIcon") {
        let tD = tableData;
        const colorToken = {
          red: token.colorError,
          green: token.colorSuccess,
          yellow: token.colorWarning,
        };
        for (let i = 0; i < tableData.length; i++) {
          tD[i].statusIcon = (
            <ExclamationCircleFilled
              style={{
                fontSize: "large",
                color: colorToken[tableData[i].status],
              }}
            />
          );
        }
        tableDataUpdate(tD);
      }
      // create filters for the column
      let filters = [];
      let elementsFiltered = [];
      for (let i = 0; i < tableData.length; i++) {
        if (!elementsFiltered.includes(tableData[i][attr.key]))
          filters.push({text: tableData[i][attr.key],value: tableData[i][attr.key]});
        elementsFiltered.push(tableData[i][attr.key]);
      }

      let temp_obj = {
        title: t(attr.name),
        dataIndex: attr.key,
        key: attr.key,
      };
      if (Object.keys(attr).includes("width")) temp_obj.width = attr.width;

      // normal column with name filter
      if (attr.filter === "name") {
        temp_obj.filters = filters;
        temp_obj.onFilter = (value, record) =>
          record[attr.key].indexOf(value) === 0;
      }
      // normal column with number filter
      if (attr.filter === "number") {
        temp_obj.sorter = (a, b) => a[attr.key] - b[attr.key];
      }
      // normal column with number filter
      if (attr.filter === "text") {
        temp_obj = { ...temp_obj, ...getColumnSearchProps(attr.key) };
      }
      // normal column with date filter
      if (attr.filter === "date") {
        temp_obj = { ...temp_obj, ...getColumnDateFilterProps() };
      }
      // normal column without filter
      temp.push(temp_obj);
    }); // foreach()

    // Once ended add action buttons
    let actionColumn = defaultAttributes.filter((e) => e.key === "actions")[0];
    // add action column
    temp.push({
      title: <SettingOutlined style={{ fontSize: "large" }} />,
      dataIndex: actionColumn.key,
      fixed: "right",
      key: "action-buttons",
      width: 150,
    });
    // Add actions to data rows,
    // WARNING: might make the program slow, an alternative is recommended

    let tD = tableData;
    for (let i = 0; i < tableData.length; i++) {
      let tempActions = [];
      let depthRoutePhysicalGateway = "";
      let depthRouteSensor = "";
      let updateAndDeletePath = route;
      if (updateAndDeleteRoute) {
        //"param/id"
        // example: "type/id"
        let params = updateAndDeleteRoute.split("/");

        updateAndDeletePath += params.reduce((acum, element) => {
          let result = "";
          result =
            element !== "id" ? (result = acum + `/${tD[i][element]}`) : acum;
          return result;
        }, "");
      } else {
        updateAndDeletePath = route;
      }
      if (
        actionColumn.actions.includes("edit") &&
        (!CRUDPrivilages || CRUDPrivilages.update)
      ) {
        tempActions.push(
          <EditButton
            route={updateAndDeletePath}
            form={form}
            attributes={allAttributes}
            element={tD[i]}
          />,
        );
      }
      if (actionColumn.actions.includes("networkServer")) {
        // This url is the depth route you will be redirected when click on networkServer Action to go inside of NS
        // and then you must click an organization
        depthRoutePhysicalGateway = `${location.pathname}/${tD[i].mac}/organizations`;
        tempActions.push(
          <RedirectButton
            route={depthRoutePhysicalGateway}
            element={tD[i]}
            alt={"NetworkServer Embebed"}
          />,
        );
      }
      if (actionColumn.actions.includes("keys")) {
        // This url is the depth route you will be redirected when click on networkServer Action to go inside of NS
        // and then you must click an organization
        depthRoutePhysicalGateway = `${location.pathname}/${tD[i].mac}/organizations`;
        tempActions.push(
          <KeysButton
            route={depthRoutePhysicalGateway}
            element={tD[i]}
            alt={"NetworkServer Embebed"}
          />,
        );
      }
      if (actionColumn.actions.includes("sensors")) {
        depthRouteSensor = `${location.pathname}/app/${tD[i]?.applicationID}/deveui/${tD[i]?.devEUI}`;
        tempActions.push(
          <RedirectButton
            route={depthRouteSensor}
            element={tD[i]}
            alt={"sensor Visualize"}
          />
        );
      }
      if (actionColumn.actions.includes("insideOrganization")) {
        // This url is the depth route you will be redirected when click on networkServer Action to go inside of NS
        // and then you must click an organization
        depthRoutePhysicalGateway = `${location.pathname}/${tD[i].id}`;
        tempActions.push(
          <RedirectButton
            route={depthRoutePhysicalGateway}
            element={tD[i]}
            alt={"Organization"}
          />,
        );
      }
      if (
        actionColumn.actions.includes("CNO") &
        AuthService.atLeastRole(posibleRoles.ADMIN_TECH)
      ) {
        // depthRoutePhysicalGateway = `${location.pathname}/${tD[i].id}`;
        tempActions.push(
          <CNOButton
            route={depthRoutePhysicalGateway}
            element={tD[i]}
            alt={"CNO"}
          />,
        );
      }

      if (actionColumn.actions.includes("mqttlog"))
        tempActions.push(<MqttLogButton element={tD[i]} />); // sends the message data as an element
      if (actionColumn.actions.includes("xml"))
        tempActions.push(<XmlButton element={tD[i]} />);
      if (actionColumn.actions.includes("editterms"))
        tempActions.push(
          <EditTermsButton
            route={route}
            attributes={additionalInput}
            element={tD[i]}
          />,
        );
      if (actionColumn.actions.includes("lock"))
        tempActions.push(<LockButton element={tD[i]} />);
      if (actionColumn.actions.includes("history"))
        tempActions.push(<HistoryListButton element={tD[i]} />);
      if (
        actionColumn.actions.includes("remove") &&
        (!CRUDPrivilages || CRUDPrivilages.delete)
      )
        tempActions.push(
          <RemoveButton
            route={updateAndDeletePath}
            element={tD[i]}
            dataName={dataName}
            onRemove={handleReloadOnRemove}
          />,
        );
      
      if (actionColumn.actions.includes("logs"))
        tempActions.push(
          <LogsButton
            route={route}
            form={form}
            attributes={allAttributes}
            element={tD[i]}
          />,
        );
      tD[i].actions = <Space>{tempActions}</Space>;
      tableDataUpdate(tD);
    }
    return temp;
  } // calculateColumns()

  /**
   * Row selection of table
   */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (title === "Applications") setApplicationChange(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className={wrapper ? "wrapper" : ""}>
      {contextHolder}
      <>
        <ContentPageHeader
          route={route}
          attributes={allAttributes}
          canAdd={canAdd}
          addForm={form}
          api={new BasicApi(route)}
          submitButton={submitButton}
          reload={() => setReloadState(!reloadState)}
          title={title}
          additionalButtons={additionalButtons}
          showColor={showColor}
          CRUDPrivilages={CRUDPrivilages}
          canUploadCsv={canUploadCsv}
          csvOptions={csvOptions}
        />
        <Spin size="middle" spinning={isTableLoading}>
          {extra ? <ComponentExtra /> : <></>}
          <Table
            className="tableva"
            dataSource={tableData}
            columns={columns}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            pagination={false}
            scroll={{ x: 1500, y: 350 }}
            onChange={onTableChange}
          />

          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total ? pagination.total : tableData?.length}
            onChange={(page, pageSize) => {
              setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize: pageSize,
              }));
              let extra = searchFilters;
              if (
                applicationChange &&
                applicationChange.length > 0 &&
                title === "Sensors"
              )
                extra = {
                  applicationID: applicationChange.map((data) => data.id),
                };
              getData(
                pagination.pageSize,
                (page - 1) * pagination.pageSize,
                extra
              );
            }}
            style={{ marginTop: "20px", textAlign: "right" }}
          />
        </Spin>
      </>
    </div>
  );
};

export default CommonTable;
