import { useEffect, useState } from "react";
import { FullDynamicTable } from "../../components/DynamicTable";
import PhysicalGatewayService from "../../services/moduleLora/PhysicalGateway/PhysicalGateway.service";
import MqttServerService from "../../services/moduleLora/MqttServer/MqttServer.service";
import { EditOutlined,DeleteOutlined,ApartmentOutlined } from '@ant-design/icons';
import { Flex, message, Space } from "antd";
import AddOrEditEntityModal from "../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import Selector from "../../components/csvUpload/Selector";
import { CRUDPrivilages } from "../../components/DynamicTable/Interfaces/CRUDPrivilages";
//import ControlCabinetTab from "./ControlCabinetTab/ControlCabinetTab";
type params = { mode: "centralized"| "embedded",CRUDPrivilages: CRUDPrivilages }
const mqttServerService = new MqttServerService();
export default function PhysicalGateway({mode="centralized",CRUDPrivilages}:params){
    const navigate = useNavigate();
    const [service,setService] = useState(new PhysicalGatewayService(mode));
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [physicalGatewayId, setPhysicalGatewayId] = useState(0);
    const [refreshTable, setRefreshTable] = useState(true);

    useEffect(()=>{
      setService(new PhysicalGatewayService(mode))
    },[mode])
    const columns = [
      ...service.getColumns(),
      {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 100,
        align: 'center',
        render: (text:string, record:any) => (
        <Flex justify="center" ><Space align="center">
            {CRUDPrivilages.update &&
              <EditOutlined 
                  style={{ fontSize: "1.8em", color: "#7150CC" }} 
                  onClick={() => {setPhysicalGatewayId(record.id);setEditModal(true);}} 
                  onPointerOverCapture={undefined} onPointerMoveCapture={undefined}              
              />
            }
            <ApartmentOutlined
            style={{ fontSize: "1.8em", color: "#7150CC" }} 
            onClick={() => {
              navigate(`${location.pathname}/${record.id}/organizations`)
            }} 
            onPointerOverCapture={undefined} onPointerMoveCapture={undefined}
            />
            {CRUDPrivilages.delete &&
              <DeleteOutlined 
                  style={{ fontSize: "1.8em", color: "#DC4446" }} 
                  onClick={() => {setPhysicalGatewayId(record.id);setDeleteModal(true);} } 
                  onPointerOverCapture={undefined} onPointerMoveCapture={undefined}
              />
            }
        </Space></Flex>
        )
        }
    ]
    return (<>
          <AddOrEditEntityModal 
          title={`Physical Gateway ${mode}`}
          type="edit"
          service={service} id={physicalGatewayId} 
          isModalOpen={editModal} setIsModalOpen={(setEditModal)}
          paramsPerPage={10}
          callback={()=>{setRefreshTable(!refreshTable)}}
          />   
          <ConfirmationModal
          callback={async ()=>{ 
            if(await service.delete(physicalGatewayId)){
              setRefreshTable(!refreshTable);
              message.success("Deleted successfully!")
            }
          }}          
          message= "Are you sure you want to delete this Gateway?"
          modalOpen={deleteModal}
          setModalOpen={setDeleteModal}
          />

          <FullDynamicTable 
          title={`Physical Gateway ${mode[0].toUpperCase() + mode.slice(1)}`}
          service={service}
          generalActions={            
            {
              add:CRUDPrivilages.create?{}:null,
              csv:{
                service: service,
                callback: () => {},
                description: "'NAME', 'MAC',	'description', 'latitude', 'longitude', 'organizationId'",
                children: () => {return (
                  <Selector
                    onChangeHandler={service.setMqttId}
                    title="Select Mqtt Server"
                    service={mqttServerService}
                    name="mqttServerId"
                  />
                )},
                options: null,
                fileExtension: ".csv" 
              },
              configColumns:{},
              deleteSelected:{}
            }
          }
          columns={columns}
          multiSelect={true}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          />
        </>)
}