import { useEffect, useState } from "react"
import { Form, Input, Row, Col } from "antd";

interface Idata {
    name?:string,
    description?:string
}
interface props {  
    originalData:Idata,
    callback:Function
}
const ScheduleGeneral: React.FC<props> = ({originalData, callback}) => {
    const [data,setData] = useState<Idata>({...originalData})
    useEffect(()=>{setData(originalData)},[originalData])
    return (
    <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
        <Form layout="vertical">
            <Row><Col >
                <Form.Item label="Nombre">
                    <Input placeholder="Nombre" value={data.name} 
                        onChange={(event)=>{
                            setData({...data,name:event.target.value})
                            callback({...data,name:event.target.value})
                        }
                    }/>
                </Form.Item>
            </Col></Row>
            <Row><Col >
                <Form.Item label="Descripción">
                    <Input placeholder="Descripción" value={data.description}
                    onChange={(event)=>{
                        setData({...data,description:event.target.value})
                        callback({...data,description:event.target.value})
                    }}
                    />
                </Form.Item>
            </Col></Row>
        </Form>
    </div>
    );
}
export default ScheduleGeneral
