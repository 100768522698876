import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
import MqttServerService from "../MqttServer/MqttServer.service";

export const columns: IColumnForCRUD[] = [
  { title: "Name", dataIndex: "name", type: "text", visible: true, editable: true },
  { title: "Mac", dataIndex: "mac", type: "text", visible: true, editable: true },
  { title: "LastSeen", dataIndex: "lastSeen", type: "lastSeen", defaultValue: "3h", visible: true, editable: false },
  { title: "Description", dataIndex: "description", type: "text", visible: true, editable: true },
  { title: "Latitude", dataIndex: "latitude", type: "number", visible: true, editable: true },
  { title: "Longitude", dataIndex: "longitude", type: "number", visible: true, editable: true },
  { title: "mqtt", dataIndex: "mqttId", type: "service", service: new MqttServerService(), serviceLabel: "profileName", serviceValue: "id", visible: false, editable: true, },
];