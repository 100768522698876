import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"

export const columns:IColumnForCRUD[] = [
    { title: "Profile Name", dataIndex: "profileName", serviceLabel:"profileName",  type: "text" },
    { title: "Description", dataIndex: "description", type: "text"},
    { title: "URL", dataIndex: "url", type: "text"},
    { title: "Port", dataIndex: "port", type: "number", },
    { title: "User", dataIndex: "user", type: "text",   },
    { title: "Password", dataIndex: "pass", type: "text",    },
    { title: "SSL/TLS", dataIndex: "ssl_tls", type: "boolean",  },
    { title: "Signed by Server",   dataIndex: "CA_signed_server_certificate", type: "boolean"},
    { title: "Certificate",   dataIndex: "certificate", type: "text",},
  ];