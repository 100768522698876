import { Flex, Transfer } from "antd";
import type { TransferProps } from 'antd';
import { useState,useEffect } from "react";
import ActuatorService from "../../../../services/moduleStreetlight/Actuator/Actuator.service";
import stGroupService from "../../../../services/moduleStreetlight/StGroup/StGroup.service";
import { IschedulesParent } from "../../../../Pages/moduleStreetlight/Interfaces";
interface props {  
  service:ActuatorService|stGroupService
  entityId:number
  callback:Function
}
const ScheduleDomain: React.FC<props> = ({
  service,entityId,callback

}) => {
    const [domainKeys, setDomainKeys] = useState<TransferProps['targetKeys']>([]);
    const [selectedKeys, setSelectedKeys] = useState<TransferProps['targetKeys']>([]);
    const [dataSource, setDataSource] = useState<any>([]);


    useEffect(()=>{
      setSelectedKeys([])
      service.getFreeStreetlights(entityId).then((data)=>setDataSource(data))
      if(entityId>0){
        service.getById(entityId).then((data:IschedulesParent)=>{
          data && "streetlightsDomain" in data?
          setDomainKeys(data.streetlightsDomain)
          :setDomainKeys([])
        })
      }
    },[entityId,service])


    const onChange: TransferProps['onChange'] = (nextTargetKeys) => {
      setDomainKeys(nextTargetKeys);
      callback(nextTargetKeys)
    };
    const onSelectChange: TransferProps['onSelectChange'] = (
      sourceSelectedKeys,
      targetSelectedKeys,
    ) => {
      setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
  
    return (
      <Flex justify="center">
        <Transfer
          dataSource={dataSource}
          titles={['Libre', 'Dominio']}
          targetKeys={domainKeys}
          selectedKeys={selectedKeys}
          onChange={onChange}
          onSelectChange={onSelectChange}
          render={(item:any) => item.title}
          />
      </Flex>
    )
}
export default ScheduleDomain
