import {  Dropdown, Flex, Modal, Select } from "antd";
import { useState } from "react";
import {  Divider } from "antd";
import { daysOfWeek, daysOfWeekValue } from "../../../utils/date";
import { MiniDynamicTable } from "../../DynamicTable";
import { IColumnForCRUD } from "../../DynamicTable/Interfaces/ColumnConfig";
import type { MenuProps } from 'antd';

import { Collapse } from 'antd';

import { EditOutlined,  DeleteOutlined } from '@ant-design/icons';
import { ItemType } from "antd/es/menu/interface";


interface IrowData {
    key:string
    intensity?:number
    power?:"on"|"off"
    startAt:`${number}:${number}`|"amanecer"|"ocaso"
    addOrEdit?:"new"|"edit"
}
interface Idata {
    days:daysOfWeekValue[]
    data:IrowData[]
}

interface ScheduleModalProps {  
    dataSource: Idata[];
    entitiesIds: number[]|number;
    modalOpen:boolean;
    setModalOpen:Function;
    columns:IColumnForCRUD[];
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({dataSource, entitiesIds,modalOpen,setModalOpen,columns}) => {
    
    let [activeRow,setActiveRow]=useState<number|undefined>(undefined)

    const [_dataSource,_setDataSource] = useState<Idata[]>(dataSource)
    const items: MenuProps['items'] = [
        {
          key: '1',
          label:
          (<>
            <Select  
                style={{width:"20em"}}
                options={daysOfWeek.map((item)=>({label:item.label,value:item.value}))}
                onClick={
                    (event)=>{
                        console.log(activeRow)
                        event.stopPropagation();
                    }
                }
                value={activeRow !==undefined?dataSource[activeRow]?.days:[]}
                onChange={(value)=>{
                    if(activeRow===undefined) return null
                    let tmp = dataSource
                    tmp[activeRow].days = value
                    _setDataSource([...tmp])
                }}
                mode="tags"/>
          </>)
        },
      ];

    const genExtra = (index:number) => (
        <Flex justify="center"  onClick={(event) => {event.stopPropagation();}}>
            <Dropdown menu={{items}}>
            <EditOutlined 
                onMouseEnter={(e) => setActiveRow(index)} 
                />
            </Dropdown> 
            <DeleteOutlined onClick={(e) => {
                let tmp = dataSource
                tmp.splice(index,1)
                _setDataSource([...tmp])
            }}/>
      </Flex>
      );
      

    return(
        <Modal open={modalOpen} onCancel={()=>setModalOpen(false)} onOk={()=>setModalOpen(false)} width={"60em"}>
            <Divider>Valores generales</Divider>
            <p>Reloj astronómico deshabilitado</p>
                    
            <p>Compensación amanecer: 0</p>
            <p>Compensación anochecer: 0</p>
            <Collapse 
                items={dataSource.map((item:Idata,index)=>{
                    let title = item.days.map((day:string)=>{return day.substring(0,2)}).join(", ")
                    return ({          
                    key: index,
                    label: title,
                    extra: genExtra(index),
                    children: 
                        <MiniDynamicTable
                            defaultValueToAdd={{
                                days: ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],
                                startAt:"00:00",
                                intensity:100,
                                power:"on"
                            }}
                            initialData={dataSource[index].data}
                            columns={columns}
                            callback={(newData:IrowData[])=>{
                                let tmp = dataSource
                                dataSource[index].data = newData
                            }}
                        />,
                    })
                })} 
                defaultActiveKey={['-1']} 
                onChange={()=>{}} 
            />

        </Modal>
    )
  
}
export default ScheduleModal