import { ENV } from "../../../utils";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";
import { getAllForAlarms } from "../../interfaces.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import axios from "axios";

export default class StaService  extends AbstractCRUD<any> implements  getAllForAlarms {
  constructor() {
    const token = AuthService.getToken();
    const url = `${ENV.Sta_API}/sta`;
    const headers = { "x-access-token": token, "Content-Type": "application/json" };
    super(url, headers); // Inicializa BaseService con URL y headers
  }

  getColumns(): IColumnForCRUD[] {
    return []; // Devuelve columnas específicas si es necesario
  }

  async getAllForAlarms(filters: any = {}, currentPage: number = 1, pageSize: number = 10): Promise<any> {
    const offset = (currentPage - 1) * pageSize;
    const response = await axios.get(
      `${ENV.Sta_API}/sta/getAllForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`,
      { headers: this.headers }
    );
    return response.data;
  }

  async allInMap(): Promise<any[]> {
    try {
      const response = await fetch(`${ENV.Sta_API}/sta/allInMap`, { headers: this.headers });
      return await response.json();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async processCsv(delimiter: string, serverFileName: string): Promise<any> {
    try {
      const body = JSON.stringify({ delimiter, file_name: serverFileName });
      const response = await fetch(`${ENV.Sta_API}/sta/process_csv`, {
        method: "POST",
        headers: this.headers,
        body,
      });
      return await response.json();
    } catch (error) {
      console.error("Error processing CSV:", error);
      return { error: "Error" };
    }
  }

  async updateSalt(devEUI: string): Promise<any> {
    const body = { devEUI };
    return await axios.post(`${this.url}/salt/state`, body, { headers: this.headers });
  }

  async writeIntervalWindow(rowsSelected: any[], intervalInSeconds: number): Promise<any> {
    const body = { dev_EUIs: rowsSelected, intervalInSeconds };
    return await axios.post(`${this.url}/interval`, body, { headers: this.headers });
  }

  async getEvents(dev_EUI: string): Promise<any> {
    return await axios.get(`${this.url}/events/${dev_EUI}`, { headers: this.headers }).then((res) => res.data);
  }

  async getSalt(dev_EUI: string): Promise<any> {
    return await axios.get(`${this.url}/salt?dev_EUI=${dev_EUI}`, { headers: this.headers }).then((res) => res.data);
  }

  async getEnums(): Promise<any> {
    return {};
  }
}
