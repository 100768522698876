import { Button, Flex, Input, Select, Table, message, Spin } from "antd";
import { useState, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  SyncOutlined,
  UploadOutlined,
  InfoOutlined,
  SettingOutlined,
  EyeOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { theme } from "antd";
import { ModalUploadCSV } from "./Modals/ModalUploadCSV/ModalUploadCSV";
import { ModalStaConfig } from "./Modals/ModalStaConfig/ModalStaConfig.jsx";
import { ModalStaInfo } from "./Modals/ModalStaInfo/ModalStaInfo";
import { ModalSaltInfo } from "./Modals/ModalSaltInfo/ModalSaltInfo";
import { ModalUpdateFirmware } from "./Modals/ModalUpdateFirmware/ModalUpdateFirmware";
import { ModalAddOrEdit } from "./Modals/ModalAddOrEdit/ModalAddOrEdit";
import { ModalDelete } from "./Modals/ModalDelete/ModalDelete";
import { ModalStaUpdateSalt } from "./Modals/ModalStaUpdateSalt";
import StaService from "../../../services/moduleSTA/Sta/Sta.service";

const { useToken } = theme;

function StaTab() {
  let [data, setData] = useState([]);
  let [dataSalt, setDataSalt] = useState([]);
  const [staService] = useState(new StaService());
  const [modal_AddOrEdit_Open, setModal_AddOrEdit_Open] = useState(false);
  const [modal_salt, setModal_salt] = useState(false);
  const [modal_CSV_Open, setModal_CSV_Open] = useState(false);
  const [modal_UpdateFirmware_Open, setModal_UpdateFirmware_Open] = useState(false);
  const [modal_staConfig_Open, setModal_StaConfig_Open] = useState(false);
  const [modal_staInfo_Open, setModal_StaInfo_Open] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [modal_ShowSalt_Open, setModal_ShowSalt_Open] = useState(false);
  const [selectedStasInModal, setSelectedStasInModal] = useState([]);
  
  // filter and pagination
  const [searchBy, setSearchBy] = useState({ diffInHours: 6 });
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  async function changeSearchBy(value, key) {
    setCurrentPage(1);
    setSearchBy({...searchBy,[`${key}`]: value});
  }
  function openDeleteModal() {
    setEntityData(data[0]);
    setIsDeleteModalOpen(true);
  }

  let [addOrEdit, setAddOrEdit] = useState("Add");
  let [entityData, setEntityData] = useState({});

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys)
  };

  const { token } = useToken();

  const updateSalt = async () => {
    if (rowSelection.selectedRowKeys.length > 10) {
      message.error(`Max 10 STA selected`);
      return;
    }
    if (rowSelection.selectedRowKeys.length === 0) {
      message.error(`Please Select STA`);
      return;
    }
    // TODO: set token
    const staService = new StaService();
    const response = await staService.updateSalt(rowSelection.selectedRowKeys);
    if (!response) {
      message.error(`Error updating salt`);
    } else {
      setDataSalt(response.data.result);
      setModal_salt(true);
    }
  };
  const modalStaConfigOnOk = async (
    intervalWindow,
    individualSta = null,
  ) => {
    const tmp_sta = individualSta ? [individualSta]: selectedStasInModal;
    const staService = new StaService()
    const response = await staService.writeIntervalWindow(tmp_sta,intervalWindow);
    if (response.status !== 200) {
      message.error(`Response ${response.status} from Interval Window`);
      return;
    }
    message.success(`Interval window response: ${response.status}`);
    setModal_StaConfig_Open(false);
  };

  function createSta() {
    setAddOrEdit("Add");
    setModal_AddOrEdit_Open(true);
    setEntityData({
      dev_EUI: "",
      name: "",
      description: "",
      latitude: "",
      longitude: "",
      connection: "lora",
    });
  }

  function editSta(data) {
    setEntityData({ old_DEV_EUI: data.dev_EUI, ...data });
    setAddOrEdit("Edit");
    setModal_AddOrEdit_Open(true);
  }

  function showInfo(data) {
    setEntityData({ ...data });
    setModal_StaInfo_Open(true);
  }

  function showSalt(data) {
    setEntityData({ ...data });
    setModal_ShowSalt_Open(true);
  }

  function configSta(data) {
    setEntityData({ ...data });
    setModal_StaConfig_Open(true);
  }
  function handleOpenStaConfi(){
    setModal_StaConfig_Open(true)
    setEntityData({});
  }

  useEffect(() => {
    setLoading(true);
    staService.getAll(searchBy, currentPage,pageSize).then((data) => {
      setData(data.result);
      setTotalCount(data.totalCount);
      setLoading(false);
    });
  }, [searchBy, currentPage, pageSize, refresh, staService]);
  return (
    <>
      {/* <Modals> */}
      {/* Specific to record */}

      <ModalAddOrEdit
        AddOrEdit={addOrEdit}
        originalData={entityData}
        modalOpen={modal_AddOrEdit_Open}
        setModalOpen={setModal_AddOrEdit_Open}
        callback={() => setRefresh(!refresh)}
      />
      <ModalStaConfig
        originalData={entityData}
        modalOpen={modal_staConfig_Open}
        setModalOpen={setModal_StaConfig_Open}
        onChangeIntervalWindow={modalStaConfigOnOk}
        setSelectedStasInModal={setSelectedStasInModal}
      />
      <ModalStaUpdateSalt
        originalData={dataSalt}
        modalOpen={modal_salt}
        setModalOpen={setModal_salt}
      />
      <ModalStaInfo
        originalData={entityData}
        modalOpen={modal_staInfo_Open}
        setModalOpen={setModal_StaInfo_Open}
        callback={() => setRefresh(!refresh)}
      />
      <ModalSaltInfo
        originalData={entityData}
        modalOpen={modal_ShowSalt_Open}
        setModalOpen={setModal_ShowSalt_Open}
      />
      <ModalDelete
        originalData={entityData}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        callback={() => setRefresh(!refresh)}
      />

      {/* GENERAL */}
      <ModalUpdateFirmware
        modalOpen={modal_UpdateFirmware_Open}
        setModalOpen={setModal_UpdateFirmware_Open}
        callback={() => setRefresh(!refresh)}
      />
      <ModalUploadCSV
        modalOpen={modal_CSV_Open}
        setModalOpen={setModal_CSV_Open}
        callback={() => setRefresh(!refresh)}
      />

      {/* </Modals> */}

      <Flex justify="flex-end">
        <Button
          type="primary"
          style={{ marginRight: "6px" }}
          className="buttonCentered"
          onClick={() => handleOpenStaConfi()}
        >
          Ventana Detección
        </Button>
        <Button
          type="primary"
          style={{ marginRight: "6px" }}
          className="buttonCentered"
          onClick={() => {
            setModal_UpdateFirmware_Open(true);
          }}
        >
          Actualizar Firmware
        </Button>
        <Button
          type="primary"
          style={{ marginRight: "6px" }}
          className="buttonCentered"
          onClick={async () => updateSalt()}
        >
          {" "}
          Actualizar Salt
        </Button>

        <Button
          type="primary"
          style={{ marginRight: "6px" }}
          icon={<UploadOutlined />}
          onClick={() => {
            setModal_CSV_Open(true);
          }}
        >
          Upload CSV
        </Button>

        <Button
          type="primary"
          className="buttonCentered"
          style={{ marginRight: "6px" }}
          onClick={() => {
            createSta();
          }}
        >
          Add
        </Button>
      </Flex>
      <Spin size="large" spinning={loading}>
        <Table
          dataSource={data}
          rowKey="dev_EUI"
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
            onChange: (page) => {setCurrentPage(page)}
          }}
          style={{ marginTop: "20px" }}
          scroll={{ y: 350 }}
          rowSelection={rowSelection}
        >
          <Table.Column
            title={() => {
              return (
                <Flex justify="center" align="center" vertical={true}>
                  <label>DEV EUI</label>
                  <Input.Search
                    placeholder="Search"
                    onInput={async (e) =>
                      await changeSearchBy(e.target.value, "dev_EUI")
                    }
                    style={{ width: 150 }}
                  />
                </Flex>
              );
            }}
            value="dev_EUI"
            key={"dev_EUI"}
            dataIndex={"dev_EUI"}
          />
          <Table.Column
            title={() => {
              return (
                <Flex justify="center" align="center" vertical={true}>
                  <label>Name</label>
                  <Input.Search
                    placeholder="Search"
                    onInput={(e) => changeSearchBy(e.target.value, "name")}
                    style={{ width: 150 }}
                  />
                </Flex>
              );
            }}
            value="name"
            key={"name"}
            dataIndex={"name"}
          />
          <Table.Column
            title="Description"
            value="description"
            key={"description"}
            dataIndex={"description"}
          />
          <Table.Column
            title="Latitude, Longitude"
            value="lat"
            dataIndex={"latitude"}
            render={(text, record) => (
              <Flex justify="flex-start">
                {record.latitude}, {record.longitude}
              </Flex>
            )}
          />
          <Table.Column
            value="lastSeen"
            key={"lastSeen"}
            dataIndex={"lastSeen"}
            title={() => {
              return (
                <Flex justify="center" align="center" vertical={true}>
                  <label>Last seen</label>
                  <Select
                    options={[
                      { label: "All", value: "all" },
                      { label: "🟩 ON", value: "ON" },
                      { label: "🟥 OFF", value: "OFF" },
                    ]}
                    defaultValue="all"
                    onChange={(e) => changeSearchBy(e, "lastSeen")}
                    style={{ width: 150 }}
                  />
                  Differnce in hours:{" "}
                  <Input
                    value={searchBy.diffInHours}
                    onChange={(e) =>
                      changeSearchBy(e.target.value, "diffInHours")
                    }
                  ></Input>
                </Flex>
              );
            }}
            render={(text, record) => {
              const lastSeen = new Date(record.lastSeen);
              const diference = searchBy.diffInHours * 60 * 60 * 1000;
              const result =
                new Date() - lastSeen > diference ? "🟥 OFF" : "🟩 ON";
              return `${result}, ${lastSeen.toLocaleString()}`;
            }}
          />

          <Table.Column
            title={() => {
              return (
                <Flex
                  justify="center"
                  align="center"
                  id="columnTitle$Connection"
                  vertical={true}
                >
                  <label>Connection</label>
                  <Select
                    options={[
                      { label: "All", value: "all" },
                      { label: "lora", value: "lora" },
                      { label: "4G", value: "4G" },
                    ]}
                    defaultValue="all"
                    onChange={(e) => changeSearchBy(e, "connection")}
                    style={{ width: 150 }}
                  />
                </Flex>
              );
            }}
            value="connection"
            key={"connection"}
            dataIndex={"connection"}
          />
          <Table.Column
            value="actualVersion"
            key={"actual"}
            dataIndex={"actual"}
            title={() => {
              return (
                <Flex justify="center" align="center" vertical={true}>
                  <label>Actual salt version</label>
                </Flex>
              );
            }}
            render={(text, record) => {
              const value =
                record.actual !== null ? (
                  <>
                    {record.actual}
                    <EyeOutlined
                      onClick={() => {
                        showSalt(record);
                      }}
                      style={{ fontSize: "large", color: "#66C3F4" }}
                    />
                    {record.actual !== record.should && record.actual !== "" ? (
                      <>
                        <WarningOutlined
                          style={{ fontSize: "large", color: "#66C3F4" }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                );
              return <Flex justify="space-around">{value}</Flex>;
            }}
          />
          <Table.Column
            value="should"
            key={"should"}
            dataIndex={"should"}
            title={() => {
              return (
                <Flex justify="center" align="center" vertical={true}>
                  <label>Should salt version</label>
                </Flex>
              );
            }}
            render={(text, record) => {
              const value =
                record.should !== null ? (
                  <>
                    {record.should}
                    <EyeOutlined
                      onClick={() => {
                        showSalt(record);
                      }}
                      style={{ fontSize: "large", color: "#66C3F4" }}
                    />
                  </>
                ) : (
                  ""
                );
              return <Flex justify="space-around">{value}</Flex>;
            }}
          />
          <Table.Column
            title="Actions"
            render={(text, record) => (
              <>
                <Flex justify="flex-start">
                  <SyncOutlined
                    onClick={null}
                    style={{ color: "#66C3F4", width: "2em" }}
                  />
                  <EditOutlined
                    onClick={() => {
                      editSta(record);
                    }}
                    style={{ fontSize: "large", color: token.colorPrimary }}
                  />
                  <DeleteOutlined
                    onClick={() => {
                      openDeleteModal();
                    }}
                    style={{ fontSize: "large", color: token.colorError }}
                  />
                  <InfoOutlined
                    onClick={() => {
                      showInfo(record);
                    }}
                    style={{ fontSize: "large", color: "yellow" }}
                  />
                  <SettingOutlined
                    onClick={() => {
                      configSta(record);
                    }}
                    style={{ fontSize: "large", color: "white" }}
                  />
                </Flex>
              </>
            )}
          />
        </Table>
      </Spin>
    </>
  );
}
export default StaTab;
