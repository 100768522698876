import { MiniDynamicTable } from '../../../DynamicTable';
import { v4 as uuidV4 } from 'uuid';

import { Button, Collapse, Dropdown, Flex, Select } from 'antd';
import { daysOfWeek } from '../../../../utils/date';
import type { MenuProps } from 'antd';
import {IColumnForCRUD} from "../../../DynamicTable/Interfaces/ColumnConfig"
import { PlusOutlined, EditOutlined,  DeleteOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import {ISchedule,IScheduleRow} from "../../../../Pages/moduleStreetlight/Interfaces"

interface props {
    originalData: ISchedule[];
    columns:IColumnForCRUD[];
    callback:Function;
    type: "Actuator"|"StGroup";
}
const ScheduleWeek: React.FC<props> = ({originalData,columns,callback=()=>{},type}) => {

    const [activeRow,setActiveRow]=useState<number|undefined>(undefined)
    const [dataSource,setDataSource] = useState<ISchedule[]>(originalData)

    useEffect(()=>{setDataSource([...originalData])},[originalData])
    
    const genExtra = (index:number) => (
        <Flex justify="center"  onClick={(event) => {event.stopPropagation();}}>
            <Dropdown menu={{items}}>
            <EditOutlined 
                onMouseEnter={(e) => setActiveRow(index)} 
                />
            </Dropdown> 
            <DeleteOutlined onClick={(e) => {
                let tmp = [...dataSource]
                tmp.splice(index,1)
                setDataSource([...tmp])
                callback([...tmp])
            }}/>
      </Flex>
      );

      const items: MenuProps['items'] = [
        {
          key: '1',
          label:
          (<>
            <Select  
                style={{width:"20em"}}
                options={daysOfWeek.map((item)=>({label:item.label,value:item.value}))}
                onClick={
                    (event)=>{
                        console.log(activeRow)
                        event.stopPropagation();
                    }
                }
                value={activeRow !==undefined?dataSource[activeRow]?.days:[]}
                onChange={(value)=>{
                    if(activeRow===undefined) return null
                    let tmp = [...dataSource]
                    tmp[activeRow].days = value
                    setDataSource([...tmp])
                    callback([...tmp])
                }}
                mode="tags"/>
          </>)
        },
      ];
    return (
    <>
    <Flex justify='flex-end'>
        <Button
            type="primary"
            style={{ margin: "6px" }}
            className="buttonCentered"
            icon={
                <PlusOutlined
                onPointerOverCapture={undefined}
                onPointerMoveCapture={undefined}
                />
            }
            onClick={() =>{
                let tmp  = [...dataSource]
                let new_data:ISchedule = {
                    id:uuidV4(),
                    scheduleRows:[{
                            id:uuidV4(),
                            startAt:"00:00",
                            intensity:type==="StGroup"?100:undefined,
                            power:type==="Actuator"?"on":undefined
                        }],
                    days:["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"]
                }
                tmp.push(new_data)
                setDataSource([...tmp])
                callback([...tmp])

            }}
        />
        </Flex>
        <Collapse 
        items={dataSource.map((item:ISchedule,index:number)=>{
            let title = item.days.map((day:string)=> day.substring(0,2)).join(", ")
            return ({          
            key: index,
            label: title,
            extra: genExtra(index),
            children: 
                <MiniDynamicTable
                    initialData={item.scheduleRows}
                    defaultValueToAdd={{
                        days: ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],
                        startAt:"00:00",
                        intensity:type==="StGroup"?100:undefined,
                        power:type==="Actuator"?"on":undefined
                    }}
                    columns={columns}
                    callback={(newData:IScheduleRow[])=>{
                        let tmp = [...dataSource]
                        tmp[index].scheduleRows = newData
                        setDataSource([...tmp])
                        callback([...tmp])
                    }}
                    rowKey='id'
                />,
            })
        })} 
        defaultActiveKey={['-1']} 
        />
        </>

    )
}
export default ScheduleWeek
