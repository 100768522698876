import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import { ENV } from "../../utils";

const { Text } = Typography;

function UserSelector({ element, dataName, title, form }) {
  const { t } = useTranslation();
  const [userService] = useState(
    new BasicApi(ENV.Auth_API_LOCAL + "/" + ENV.API_ROUTES.USER),
  );
  const [users, setUsers] = useState([]);

  const onValueChange = (value) => {
    form.setFieldValue(dataName, value);
  };

  useEffect(() => {
    userService.getAll().then((value) => setUsers(value.db.result));
  }, [userService]);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[
        {
          type: "number",
          required: true,
        },
      ]}
    >
      <>
        <Select
          showSearch
          style={{
            width: 200,
          }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={users.map((user) => ({
            label: user.username,
            value: user.id,
          }))}
          onChange={onValueChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </>
    </Form.Item>
  );
}

export default UserSelector;
