import { ENV } from "../../../utils";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import { Columns } from "./Columns";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";

export default class ApplicationService extends AbstractCRUD<any> {
  mac?: string;
  organization?: string;
  columns: IColumnForCRUD[];

  constructor(mac: string | undefined = undefined, organization?: string | undefined) {
    const token = AuthService.getToken();
    const url = `${ENV.Infraestructure_API}/${mac}/applications`;
    const headers = { "x-access-token": token, "Content-Type": "application/json" };
    super(url, headers);
    this.mac = mac;
    this.organization = organization;
    this.columns = Columns(this.mac as string, this.organization as string); 
  }

  getColumns(): IColumnForCRUD[] {
    return this.columns;
  }

  async getEnums(): Promise<any> {
    return {};
  }

  async processCsv(
    delimiter: string,
    serverFileName: string,
    aditionalData: any | null
  ): Promise<any> {
    return {};
  }
}
