import { ENV } from "../../../utils";
import AuthService from "../Auth/Auth.service";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";
import { Cache, uploadCSV } from "../../interfaces.service";
import { UserColumns } from "./Columns";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import axios from "axios";

export default class UserService
  extends AbstractCRUD<any>
  implements uploadCSV
{
  cache: Cache | undefined;
  uploadEndpoint: string;
  columns: IColumnForCRUD[];

  constructor() {
    const token = AuthService.getToken();
    const url = `${ENV.Auth_API}/user`;
    const headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };
    super(url, headers); // Llama al constructor de BaseService
    this.uploadEndpoint = `${url}/upload`;
    this.columns = UserColumns;
  }

  getColumns(): IColumnForCRUD[] {
    return this.columns;
  }

  async processCsv(
    delimiter: string = "",
    serverFileName: string,
    aditionalData: any = null
  ): Promise<any> {
    const body = JSON.stringify({
      ...aditionalData,
      delimiter,
      file_name: serverFileName,
    });
    return await  axios.post(`${this.url}/process_csv`, body);
  }

  async getAllRoles(): Promise<any[]> {
    const result = await axios.get(`${ENV.Auth_API}/user/getRoles`);
    return result.data || [];
  }

  async getEnums(): Promise<any> {
    // Implementación específica para obtener enums
  }
}
