import React from "react";
import App from "../App";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, ScrollRestoration, useParams } from "react-router-dom";
// STYLES
import "../styles/index.css";
// COMPONENTS
import Home from "../Pages/Home/home.component";
import { PrivateRoute } from "./utils/PrivateRoute";

// UTILS
import { withParams } from "./utils/WithParams";

// PAGES
import AlarmSystem from "../../src/Pages/AlarmSystem/AlarmSystem";
import Sta from "../Pages/Sta/Sta";
import ConCabChilds from "../Pages/moduleStreetlight/ControlCabinetChilds/ControlCabinetChilds";
import ControlCabinet from "../Pages/moduleStreetlight/ControlCabinet/ControlCabinet";
import GatewaysAndSensorsPage from "../Pages/GatewaysAndSensorsPage/GatewaysAndSensorsPage";

// ROUTES
import { UserRoutes, UserGroupsRoutes, AuthRoutes, LoraWanRoutes, DecoderRoutes, GatewayRoutes, MqttRoutes, SensorRoutes } from "./Routes";
import MyProfile from "../Pages/MyProfile/myProfile.component";
import StGroup from "../Pages/moduleStreetlight/StGroup/StGroup";

function AppRouter(): JSX.Element {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {AuthRoutes()}
        <Route path="/" element={<PrivateRoute><App /><ScrollRestoration /></PrivateRoute>} >
          <Route path="home" element={<Home />}>
            <Route path="myuser" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
            <Route index element={<GatewaysAndSensorsPage />} />
            {SensorRoutes()}
            {UserRoutes()}
            {LoraWanRoutes()}
            {DecoderRoutes()}
            {GatewayRoutes()}
            {MqttRoutes()}
            {UserRoutes()}
            {UserGroupsRoutes()}
            
            <Route path="alarms" element={<PrivateRoute children={<AlarmSystem alarmsHardware={["gateways", "devices"]} alarmServices={undefined} />} />} />
            <Route path="STA" element={<PrivateRoute children={<Sta />} />} />
            <Route path="ControlCabinet/:ControlCabinetId" element={<PrivateRoute>{React.createElement(withParams(ConCabChilds))}</PrivateRoute>} />
            <Route path="ControlCabinet/:ControlCabinetId/Actuator/:ActuatorId/stGroup" element={<PrivateRoute>{React.createElement(withParams(StGroup))}</PrivateRoute>} />
            <Route path="ControlCabinet" element={<PrivateRoute children={<ControlCabinet />} />} />
          </Route>
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}

export default AppRouter;
