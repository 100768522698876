import { IColumnForCRUD } from '../../../components/DynamicTable/Interfaces/ColumnConfig';
import CountryService from '../../general/Geo/Countries.service';
import UserService from '../User/User.service';

export const CouncilColuns:IColumnForCRUD[] = [

    {dataIndex: 'name', title: 'Name', type: 'text'},
    {dataIndex: 'address', title: 'Address', type: 'text'},
    {dataIndex: 'contact_person', title: 'Contact Person ', type: 'service',service: new UserService()},
    {dataIndex: 'phone', title: 'Phone',  type: 'text'},
    {dataIndex: 'country', title: 'Country', type: 'service', service: new CountryService()},
    // TODO: review hay que hacer muchos cambios para que obtenga del servicio getAll(:idCountry) desde addEditEntity 
   // {dataIndex: 'state', title: 'State', required: false, , filter: 'text',  service: new StateService(), type: 'service',  visible: true, editable: true},
    {dataIndex: 'locality', title: 'City', type: 'text'},
    {dataIndex: 'user_creator', title: 'User Creator ', type: 'text', editable: false, visible:false},
    {dataIndex: 'user_last_modificated', title: 'User Last  Modified',type:  'text', editable: false, visible:false},
    {dataIndex: 'web', title: 'Web',  type: 'text'},
    {dataIndex: 'postalCode', title: 'Postal Code ',type: 'text'},
    {dataIndex: 'users', title: 'Users', type: 'service', service: new UserService(), serviceMultipleSelect:true },

]