import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"

export const Columns =  (): IColumnForCRUD[] => {
    return [
        { title: "lastSeen", dataIndex: "lastSeen", type: "lastSeen", lastSeenDiff: "3h", editable: false },
        { title: "id", dataIndex: "id", type: "text", visible: true },
        { title: "name", dataIndex: "name", type: "text", visible: true },
        { title: "description", dataIndex: "description", type: "text", visible: true },
        { title: "gatewayProfileID", dataIndex: "gatewayProfileID", type: "text", visible: true },
        { title: "tags", dataIndex: "tags", type: "text", visible: true },
        { title: "location", dataIndex: "location", type: "text", visible: true, defaultValue: `{"latitude":0,"longitude":0,"altitude":0,"source":"UNKNOWN","accuracy":0}`,},
      ];
}
