import { Button, Flex, Select, TimePicker } from "antd"
import dayjs, { isDayjs } from "dayjs"
import { useState } from "react"

type params = {
    dateFormat:string
    activeRow:any
    setActiveRow:Function
    columnDataIndex:string
}
const AmanecerOcasoHorario: React.FC<params> = ({dateFormat,activeRow,setActiveRow,columnDataIndex}) => {
    const stringOptions:("amancer"|"ocaso"|"horario")[] = ["amancer","ocaso","horario"]
    let [stringValue,setStringValue] = useState<string>("select option")
      
    return (<>
    <Flex vertical>
        <Select
            style={{width:"50%"}}
            value={ stringValue||"select option"}
            options={stringOptions.map((item)=>({value:item,label:item}))}
            onChange={(value)=>{
                setStringValue(value)
                setActiveRow({...activeRow,[columnDataIndex]:value})
            }}
        />

        <TimePicker 
        style={{width:"50%"}}
        format={dateFormat}
        value={ isDayjs(activeRow?.[columnDataIndex])?activeRow?.[columnDataIndex]:dayjs(new Date())}
        disabled={["amancer","ocaso"].includes(stringValue)}
        onChange={(value)=>{
            if(activeRow===undefined) return null
            setActiveRow({...activeRow,[columnDataIndex]:value})
        }}
        />
        </Flex>
    </>)
}
export default AmanecerOcasoHorario