import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
import OrganizationService from "../Organization/Organization.service";
import ServiceProfileService from "../ServiceProfile/ServiceProfile.service";

export const Columns =  (mac: string, organization:string): IColumnForCRUD[] => {
    return [
        { dataIndex: 'name', title: 'Name', type: 'text' },
        { dataIndex: 'description', title: 'Description', type: 'text' },
        { dataIndex: 'organizationID', title: 'Organization ID', type: 'service', service: new OrganizationService(mac), defaultValue: organization, disabled:true , serviceLabel: "name", serviceValue: "id" },
        { dataIndex: 'serviceProfileID', title: 'Service Profile', type: 'service', service: new ServiceProfileService(mac), serviceLabel: "name", serviceValue: "id"}
    ];
}
