import { Tabs } from "antd";
import { useEffect, useState } from "react";

import SensorsWithoutTopicTab from "./Table/SensorsWithoutTopicTab";
import { Map } from "../../components/Map/map.component";
import SensorWithoutTopicService from "../../services/moduleLora/SensorWithoutTopic/SensorWithoutTopic.service";
export default function ControlCabinet() {
  let [tabValue, setTabValue] = useState("map");
  const [deviceService] = useState(new SensorWithoutTopicService());
  const [dataMap, setDataMap] = useState<any>([]);
  useEffect(() => {
    deviceService.getAll({},0,0).then((data:any) =>setDataMap(data.data))
  },[])
  const items = [
    {
      key: "map",
      label: "Map",
      children: <Map data={dataMap} />,
    },
    {
      key: "table",
      label: "Tabla",
      children: <SensorsWithoutTopicTab />,
    }
  ];

  return (
    <>
    <h1>Sensors</h1>
    <Tabs activeKey={tabValue} items={items} onChange={setTabValue} />
    </>
  )
  
}
