import React from "react";
import { Tabs } from "antd";
import Alarms from "../../AlarmSystem/Alarms/Alarms";
import Notification from "../../AlarmSystem/Notifications/Notifications";
import { useState } from "react";

import StreetlightTab from "../StreetlightTab/StreetlightTab";
import StreetlightService from "../../../services/moduleStreetlight/Streetlight/Streetlight.service";
import ActuatorTab from "../ActuatorTab/ActuatorTab";

export default function ControlCabinetChilds({ControlCabinetId}: {ControlCabinetId: number}) {
  let [tabValue, setTabValue] = useState<string>("streetlight");

  const items: any = [
    {
      key: "streetlight",
      label: "Streetlight",
      children: <StreetlightTab ControlCabinetId={ControlCabinetId} />,
    },
    {
      key: "actuator",
      label: "Actuadores",
      children: <ActuatorTab ControlCabinetId={ControlCabinetId} />,
    },
    {
      key: "alarms",
      label: "Alarms",
      children: <Alarms 
                  hardwares={["streetlights"]} 
                  hardwareServices={[new StreetlightService(ControlCabinetId)]}
                  optionTypes={{ "connection": {}, "value": {"options":["voltage","current","activePower","powerFactor"]} }} 
                />,
    },
    {
      key: "notifications",
      label: "Notifications",
      children: <Notification hardwares={["streetlights"]} />,
    },
  ];

  return <Tabs activeKey={tabValue} items={items} onChange={setTabValue} />;
}
