import {  Modal, Tabs } from "antd";
import { useEffect, useState } from "react";

import type { TabsProps } from 'antd';
import ScheduleWeek from "./ScheduleWeek/ScheduleWeek";
import ScheduleDomain from "./ScheduleDomain/ScheduleDomain";
import ActuatorService from "../../../services/moduleStreetlight/Actuator/Actuator.service";
import {IschedulesParent, ISchedule} from '../../../Pages/moduleStreetlight/Interfaces'
import stGroupService from "../../../services/moduleStreetlight/StGroup/StGroup.service";
import {IColumnForCRUD} from "../../DynamicTable/Interfaces/ColumnConfig"
import ScheduleGeneral from "./ScheduleGeneral/ScheduleGeneral";


interface props {  
    modalOpen:boolean;
    setModalOpen:Function;
    service:ActuatorService|stGroupService;
    entityId:number
    scheduleParentType: "Actuator"|"StGroup"
    callback:Function
}
const ScheduleParentModal: React.FC<props> = ({modalOpen,setModalOpen,service,entityId,scheduleParentType,callback=()=>{}}) => {
    const [dataSource,setDataSource] = useState<IschedulesParent>({
      general:{
        name:"sin-nombre",
        description:"sin-descripción",
      },
      schedules:[],
      streetlightsDomain:[]
    })
    const [scheduleColumns,setScheduleColumns] = useState<IColumnForCRUD[]>([
      {title: 'Inicio',dataIndex: 'startAt',type:"amanecerOcaso",dateFormat:"HH:mm"},
      {title: 'power',dataIndex: 'power',type:"enum",enumData:[{label:"on",value:"on"},{label:"off",value:"off"}]}
    ])
    const [activeKey,setActiveKey] = useState<string>("General")
    const sendData = async()=>{
      entityId===-1?
        await service.create({...dataSource}):
        await service.update(entityId,{...dataSource})
      callback()
      setModalOpen(false)
      }

    useEffect(()=>{
      if(entityId>-1){
        service.getById(entityId).then((data:IschedulesParent)=>{
          setDataSource(data)
        })

      }else{
        setDataSource({
          general:{name:"",description:""},
          schedules:[],
          streetlightsDomain:[]
        })
      }
      setActiveKey("General")
    },[entityId])

    useEffect(()=>{
      
      //if type==="Actuator"{set column to power}else{set column to intensity}
      let tmp = [...scheduleColumns]
      tmp[1] = scheduleParentType ==="Actuator"?
        {title: 'power',dataIndex: 'power',type:"enum",enumData:[{label:"on",value:"on"},{label:"off",value:"off"}]}:
        {title: 'intensity',dataIndex: 'intensity',type:"number"}
      setScheduleColumns(tmp)
    },[scheduleParentType])
      
      const items: TabsProps['items'] = [
        {
            key: 'General',
            label: 'General',
            children: <ScheduleGeneral
                        originalData={dataSource.general}
                        callback={(data:{name:string,description:string})=>
                          setDataSource({...dataSource,general:data})
                        }
                      />
        },
        {
            key: 'Domains',
            label: 'Domains',
            children:   <ScheduleDomain 
                          entityId={entityId}
                          service={service}
                          callback={(newData:string[])=>{
                              setDataSource({...dataSource,streetlightsDomain:newData})
                          }
                          }
                        />
        },
        {
            key: 'Agenda',
            label: 'Agenda Semanal',
            children:   <ScheduleWeek
                            originalData={dataSource.schedules}
                            columns= {scheduleColumns}
                            callback={(newData:ISchedule[])=>
                              setDataSource({...dataSource,schedules:newData})
                            }
                            type={scheduleParentType}
                        />
        }
      ];
    return(
        <Modal open={modalOpen} onCancel={()=>setModalOpen(false)} onOk={sendData} width={"60em"}>
            <Tabs 
              items={items} 
              activeKey={activeKey}
              onChange={(item)=>{setActiveKey(item)}}
              />
        </Modal>
    )
  
}
export default ScheduleParentModal