import { ENV } from "../../../utils";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import { columns } from "./Columns";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD"; 

export default class OrganizationService extends AbstractCRUD<any> {
  mac: string | undefined;

  constructor(mac: string | undefined = undefined) {
    const token = AuthService.getToken();
    const url = `${ENV.Infraestructure_API}/${mac}/organizations`;
    const headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };

    super(url, headers);
    this.mac = mac;
  }

  async getEnums(): Promise<any> {
    return {};
  }
  getColumns(): IColumnForCRUD[] {
    return columns;
  }

}
