import { Flex, Input, theme } from "antd";
import { Button, Col, Row, Select, Table } from "antd";
import { useState, useEffect } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import NotificationService from "../../../services/moduleAlarms/Notification/Notification.service";
import { HardwareTypes } from "../../../services/moduleAlarms/Alarms/Alarm.interfaces";
const { useToken } = theme;

function Notifications({ hardwares }: { hardwares: HardwareTypes[] }) {
  const [notificationService] = useState(new NotificationService(hardwares));
  const [dataSource, setDataSource] = useState<any>({ total: 0, result: [] });
  const { token } = useToken();
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  let hardwareType_options = hardwares.map((item) => {
    return { value: item, label: item };
  });
  const [filter, setFilter] = useState<any>({
    hardware_type: hardwareType_options.map((item) => item.value),
  });
  let status_options = [
    { value: "GENERATED", label: "GENERATED", disabled: true },
    { value: "IN_PROCESS", label: "IN_PROCESS" },
    { value: "SOLVED", label: "SOLVED" },
    { value: "CANCELLED", label: "CANCELLED" },
  ];
  const changeState = async (new_value: any, index: number) => {
    await notificationService.update(dataSource.result[index].id, {
      status: new_value,
    });
    setRefreshTable(!refreshTable);
  };
  const deleteNotification = async (id: number) => {
    await notificationService.delete(id);
    setRefreshTable(!refreshTable);
  };

  useEffect(() => {
    notificationService.getAll(filter,currentPage,pageSize).then((data) => {
      setDataSource(data)
    })
  }, [filter, notificationService,refreshTable]);
  return (
    <>
      <Row align="middle" justify="space-between">
        <Col></Col>
        <Col style={{ padding: "1rem" }}>
          <Button
            type="primary"
            disabled={true}
            className="buttonCentered"
            icon={
              <PlusOutlined
                onPointerOverCapture={undefined}
                onPointerMoveCapture={undefined}
              />
            }
          />
        </Col>
      </Row>
      <Table
        rowKey="id"
        dataSource={dataSource.result}
        scroll={{ y: 350, x: 300 }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: dataSource.totalCount,
          onChange: (page, limit) => {
            setCurrentPage(page);
            setPageSize(limit);
            setRefreshTable(!refreshTable);
          },
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title={
            <Flex vertical>
              <>Id:</>
              <Input
                value={filter.id}
                onChange={(event) => {
                  if (event.target.value === "") {
                    let tmp = { ...filter };
                    delete tmp.id;
                    setFilter(tmp);
                  } else {
                    setFilter({ ...filter, id: event.target.value });
                  }
                }}
              />
            </Flex>
          }
        />
        <Table.Column
          dataIndex="alarm"
          key="alarm"
          title={
            <Flex vertical>
              <>Alarm name:</>
              <Input
                value={filter.alarm_name}
                onChange={(event) => {
                  if (event.target.value === "") {
                    let tmp = { ...filter };
                    delete tmp.alarm_name;
                    setFilter(tmp);
                  } else {
                    setFilter({ ...filter, alarm_name: event.target.value });
                  }
                }}
              />
            </Flex>
          }
        />
        <Table.Column
          dataIndex="hardware_id"
          key="hardware_id"
          title={
            <Flex vertical>
              <>Hardware id:</>
              <Input
                value={filter.hardware_id}
                onChange={(event) => {
                  if (event.target.value === "") {
                    let tmp = { ...filter };
                    delete tmp.hardware_id;
                    setFilter(tmp);
                  } else {
                    setFilter({ ...filter, hardware_id: event.target.value });
                  }
                }}
              />
            </Flex>
          }
        />
        <Table.Column
          dataIndex="hardware_type"
          key="hardware_type"
          title={
            <Flex vertical>
              <>Hardware type:</>
              <Select
                mode="multiple"
                style={{ width: 120 }}
                value={filter.hardware_type}
                onChange={(event) => {
                  event.length > 0
                    ? setFilter({ ...filter, hardware_type: event })
                    : setFilter({
                        ...filter,
                        hardware_type: hardwareType_options.map(
                          (item) => item.value,
                        ),
                      });
                }}
                options={hardwareType_options}
              />
            </Flex>
          }
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={
            <Flex vertical>
              <>Status:</>
              <Select
                mode="multiple"
                style={{ width: 120 }}
                value={filter.status}
                onChange={(event) => {
                  if (event.length === 0) {
                    let tmp = { ...filter };
                    delete tmp.status;
                    setFilter(tmp);
                  } else {
                    setFilter({ ...filter, status: event });
                  }
                }}
                options={status_options.map((item) => {
                  return { value: item.value, label: item.label };
                })}
              />
            </Flex>
          }
          render={(text, record: any, index) => (
            <Select
              style={{ width: 120 }}
              onChange={(event) => changeState(event, index)}
              value={record.status}
              options={status_options}
            />
          )}
        />
        <Table.Column
          title="actions"
          dataIndex="actions"
          key="actions"
          render={(text, record: any, index) => (
            <DeleteOutlined
              onClick={() => {
                deleteNotification(record.id);
              }}
              style={{ fontSize: "large", color: token.colorError }}
              onPointerOverCapture={undefined}
              onPointerMoveCapture={undefined}
            />
          )}
        />
      </Table>
    </>
  );
}
export default Notifications;
