import React, { useState, useEffect } from "react";
import { message, Table } from "antd";
import { Button, Flex, Spin } from "antd";
import ModalAddSensor from "./ModalAddSensor/ModalAddSensor";
import { ENV } from "../../../utils/constants.js";
import { DeleteOutlined } from "@ant-design/icons";
import AuthService from "../../../services/moduleAuth/Auth/Auth.service";

function TabSensor({ mac, organizationId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    { title: "id", dataIndex: "id", key: "id" },
    { title: "Device EUI", dataIndex: "loraAddress", key: "loraAddress" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Sensor Type", dataIndex: "sensorType", key: "sensorType" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          style={{ margin: "8px" }}
          onClick={() => handleDelete(record)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const [loading, setLoading] = useState(true);

  const handleDelete = async (record) => {
    try {
      const response = await fetch(
        `${ENV.Infraestructure_API}/${mac}/decoder/sensors/${record.id}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ sensorType: record.sensorType }),
        },
      );
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud: ${response.status}`);
      }
      message.success("Sensor eliminado correctamente!");
    } catch (error) {
      message.success(error.message);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setLoading(false);
    fetch(
      `${ENV.Infraestructure_API}/${mac}/decoder/${organizationId}/sensors`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": AuthService.getToken(),
        },
      },
    )
      .then((response) => response.json())
      .then((data) => setDataSource(data))
      .then(() => setLoading(false))
      .catch((error) => console.error(error.message));
  }, [mac]);
  return (
    <>
      <ModalAddSensor
        mac={mac}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Flex gap="small" wrap="wrap" justify="flex-end">
        <Button type="secondary">Save</Button>
        <Button type="secondary">Get from Decoder</Button>
        <Button type="primary" onClick={showModal}>
          Add Sensor
        </Button>
        <Button type="secondary">Update Sentilo</Button>
      </Flex>

      <Spin spinning={loading} size="large">
        <Table dataSource={dataSource} columns={columns} scroll={{ y: 350 }} />
      </Spin>
    </>
  );
}
export default TabSensor;
