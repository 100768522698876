import { DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { enableInputGroupAtom } from "../../components/UpdateItemForm/CommonForm.jsx";

function DateInput({ dataName, title, disabledTargetValue, disabledVarName }) {
  const { t } = useTranslation();
  const [enableInputGroup] = useAtom(enableInputGroupAtom);

  return (
    <Form.Item key={dataName} label={t(title)} name={dataName}>
      <DatePicker
        disabled={disabledTargetValue === enableInputGroup[disabledVarName]}
      />
    </Form.Item>
  );
}

export default DateInput;
