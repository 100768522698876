import { useEffect, useState,useMemo, useCallback } from "react"
import {IColumnForCRUD} from "../Interfaces/ColumnConfig"
import { Button, Flex, InputNumber, Select, Table, TimePicker } from "antd"
import dayjs,{ Dayjs } from "dayjs"
import type { TablePaginationConfig } from 'antd/es/table/interface';
import { daysOfWeek,daysOfWeekValue } from "../../../utils/date";
import { v4 as uuidV4 } from 'uuid';
import {CheckOutlined,CloseOutlined, EditOutlined, DeleteOutlined,PlusOutlined } from "@ant-design/icons";
import './styles.css'
import AmanecerOcasoHorario from "./components/AmanecerOcasoHorario";

type MiniDynamicTableParams = {
    initialData:any,
    defaultValueToAdd:any
    columns:IColumnForCRUD[],
    callback?:Function,
    rowKey?:string
}
const createDynamicColumns = (
    columns: IColumnForCRUD[],
    activeRow: any,
    setActiveRow: (row: any) => void
  ) => {
    return columns.map((column) => {
      switch (column.type) {
        case "amanecerOcaso":
          return {
            ...column,
            render: (value: Dayjs | "amancer" | "ocaso", record: any) =>
              record.addOrEdit ? (
                <AmanecerOcasoHorario
                  columnDataIndex={column.dataIndex}
                  dateFormat={column.dateFormat || "HH:mm"}
                  activeRow={activeRow}
                  setActiveRow={setActiveRow}
                />
              ) : (
                `${dayjs.isDayjs(value) ? value.format(column.dateFormat) : value}`
              ),
          };
        case "date":
          return {
            ...column,
            render: (value: Dayjs, record: any) =>
              record.addOrEdit ? (
                <TimePicker
                  format={column.dateFormat}
                  value={activeRow?.[column.dataIndex]}
                  onChange={(value) => {
                    if (!activeRow) return;
                    setActiveRow({ ...activeRow, [column.dataIndex]: value });
                  }}
                />
              ) : (
                `${value.format(column.dateFormat)}`
              ),
          };
        case "number":
          return {
            ...column,
            render: (value: string, record: any) =>
              record.addOrEdit ? (
                <InputNumber
                  min={0}
                  max={100}
                  placeholder="100"
                  value={activeRow?.[column.dataIndex]}
                  onChange={(value) => {
                    if (!activeRow) return;
                    setActiveRow({ ...activeRow, [column.dataIndex]: value as number });
                  }}
                />
              ) : (
                value
              ),
          };
        case "enum":
          return {
            ...column,
            render: (value: string, record: any) =>
              record.addOrEdit ? (
                <Select
                  style={{ width: "100%" }}
                  value={activeRow?.[column.dataIndex]}
                  options={column.enumData}
                  onChange={(value) => {
                    if (!activeRow) return;
                    setActiveRow({ ...activeRow, [column.dataIndex]: value });
                  }}
                />
              ) : (
                value
              ),
          };
        case "dayOfWeek":
          return {
            ...column,
            render: (days: daysOfWeekValue[], record: any) =>
              record.addOrEdit ? (
                <Select
                  options={daysOfWeek.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  value={activeRow?.days}
                  onChange={(value) => {
                    if (!activeRow) return;
                    setActiveRow({ ...activeRow, [column.dataIndex]: value });
                  }}
                  mode="tags"
                />
              ) : (
                days.map((day: string) => day.substring(0, 2)).join(", ")
              ),
          };
        default:
          return column;
      }
    });
  };
const MiniDynamicTable: React.FC<MiniDynamicTableParams> = ({initialData,columns,callback=()=>{},rowKey="key",defaultValueToAdd}) => {
    const [dataSource,setDataSource] = useState<any[]>(initialData)
    const [activeRow,setActiveRow] = useState<any>(undefined)
    const [pagination,setPagination] = useState<TablePaginationConfig>({current:1,pageSize:4})
    // Memoizar columnas dinámicas
    const dynamicColumns = useMemo(
        () =>createDynamicColumns(columns,activeRow,setActiveRow),
        [columns, activeRow]
    );

    const addElement = useCallback(() => {
        let tmp:any[] = [...dataSource]
        const new_item = {...defaultValueToAdd,addOrEdit: "new",[rowKey]:uuidV4()}
        tmp.push(new_item)
        setDataSource([...tmp])
        setActiveRow(new_item)
        setPagination((prev) => ({
            ...prev,
            current: Math.ceil(tmp.length / (prev.pageSize as number)),
        }));
    },[dataSource,activeRow]);

    const deleteElement = useCallback((key: string) => {
        setDataSource((prev) => prev.filter((item) => item[rowKey] !== key));
    },[dataSource]);

    const editElement = useCallback((key: string) => {
        setDataSource((prev) => {
            const tmp = [...prev];
            const rowIndex = tmp.findIndex((item) => item[rowKey] === key);
            if (rowIndex === -1) {
                console.error(`No se encontró el elemento con key: ${key}`);
                return tmp;
            }
            tmp[rowIndex].addOrEdit = "edit";
            setActiveRow(tmp[rowIndex]);
            return tmp;
        });
    }, [rowKey,activeRow]);

    const confirmAddOrEdit = useCallback((key: string) => {
        setDataSource((prev) => {
            const tmp = [...prev];
            const rowIndex = tmp.findIndex((item) => item[rowKey] === key);
            if (rowIndex === -1) {
                console.error(`No se encontró el elemento con key: ${key}`);
                return tmp;
            }
            const activeRowTemp = { ...activeRow };
            delete activeRowTemp["addOrEdit"];
            tmp[rowIndex] = activeRowTemp;
            return tmp;
        });
        setActiveRow(undefined);
    }, [dataSource,activeRow, rowKey]);

    const discardAddOrEdit = useCallback((key: string) => {
        setDataSource((prev) => {
            const tmp = [...prev];
            const rowIndex = tmp.findIndex((item) => item[rowKey] === key);
            if (rowIndex === -1) {
                console.error(`No se encontró el elemento con key: ${key}`);
                return tmp;
            }
    
            if (activeRow?.addOrEdit === "new") {
                tmp.pop(); // Eliminar la última fila si es un nuevo registro.
            } else {
                delete tmp[rowIndex].addOrEdit; // Eliminar solo la propiedad `addOrEdit`.
            }
    
            return tmp;
        });
        setActiveRow(undefined); // Limpia la fila activa.
    }, [dataSource,activeRow, rowKey]);

    const actionColumn = useMemo(() => ({
        title: 'Acciones',
        dataIndex: 'actions',
        render: (_: any, record: any, index: number) =>
          record.addOrEdit ? (
            <>
              <CheckOutlined
                onClick={() => confirmAddOrEdit(record[rowKey])}
                style={{ marginRight: 8, cursor: "pointer" }}
              />
              <CloseOutlined
                onClick={() => discardAddOrEdit(record[rowKey])}
                style={{ cursor: "pointer" }}
              />
            </>
          ) : (
            <>
              <EditOutlined
                onClick={() => activeRow === undefined && editElement(record[rowKey])}
                style={{
                  marginRight: 8,
                  cursor: activeRow === undefined ? "pointer" : "not-allowed",
                }}
                disabled={activeRow !== undefined}
              />
              <DeleteOutlined
                onClick={() => activeRow === undefined && deleteElement(record[rowKey])}
                style={{
                  cursor: activeRow === undefined ? "pointer" : "not-allowed",
                }}
                disabled={activeRow !== undefined}
              />
            </>
          ),
      }), [activeRow]);
   
      useEffect(()=>{callback([...dataSource])},[dataSource])

    return(
    <>
        <Flex justify="right">
            <Button
            type="primary"
            style={{ margin: "6px" }}
            className="buttonCentered"
            icon={
              <PlusOutlined
                onPointerOverCapture={undefined}
                onPointerMoveCapture={undefined}
                />
            }
            disabled={activeRow!==undefined}
            onClick={addElement}
            />
        </Flex> 
        <Table 
            pagination={pagination}
            onChange={(item:any)=>{setPagination(item)}}
            rowClassName={(record) => {
                let key = rowKey
                return (activeRow !== undefined && record[key]===activeRow[key]) ? `highlight-row` : ''}
            }
            dataSource={[...dataSource]} 
            columns={[...dynamicColumns, actionColumn]}
            rowKey={rowKey}
        /> 
    </>

    )
}
export default MiniDynamicTable