import { ENV } from "../../../utils";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { columns } from "./Columns";
import axios from "axios";

export default class PhysicalGatewayService extends AbstractCRUD<any> {
  mqttId?: number;
  columns: IColumnForCRUD[];

  constructor(mode: "centralized" | "embedded" | undefined = undefined) {
    const token = AuthService.getToken();
    let url = `${ENV.Infraestructure_API}/physical-gateway`;
    url += mode ? `-${mode}` : "";
    const headers = { "x-access-token": token, "Content-Type": "application/json" };
    super(url, headers); 
    this.mqttId = undefined;
    this.columns = columns
  } 

  setMqttId(mqttId: number): void {
    this.mqttId = mqttId;
  }

  getColumns(): IColumnForCRUD[] {
    return this.columns;
  }
  
  async getEnums(): Promise<any> {
    return {};
  }

  async isCentralized(topic: string): Promise<boolean> {
    const response = await axios.get(`${ENV.Infraestructure_API}/physical-gateway/isCentralized/${topic}`);
    return response.data?.isCentralized ?? false;
  }

  async processCsv(delimiter: string, serverFileName: string, aditionalData: any = null): Promise<any> {
    const body = JSON.stringify({
      ...aditionalData,
      delimiter,
      file_name: serverFileName,
      mqttServerId: this.mqttId,
    });
    return await axios.post(`${ENV.Infraestructure_API}/physical-gateway/process_csv`, body);
  }
}
