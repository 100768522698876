import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
export const Columns =  (): IColumnForCRUD[] => {
    return [
      { title: "lastSeen",dataIndex: "lastSeen",type: "lastSeen",lastSeenDiff: "6h", editable: false},
      { title: "id_plataforma", dataIndex: "id_platform", type: "text" },
      { title: "id_uvax", dataIndex: "id_uvax", type: "text" },
      { title: "is static", dataIndex: "isStatic", type: "boolean" },
      { title: "latitude", dataIndex: "latitude", type: "float" },
      { title: "longitude", dataIndex: "longitude", type: "float" },
      /*{ title: 'cuadro',dataIndex: 'cuadro',type: "text"},*/
      { title: "circuito", dataIndex: "circuit", type: "text" },
      { title: "poblacion", dataIndex: "poblation", type: "text" },
      { title: "descripcion", dataIndex: "description", type: "text" },
    ];
}
