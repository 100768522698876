import { ENV } from "../../../utils";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { Columns } from "./columns";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";

export default class DeviceProfileService
  extends AbstractCRUD<any>
{
  mac: string | undefined;
  organization: string | undefined;

  constructor(mac: string | undefined = undefined, organization:string | undefined = undefined,) {
    const token = AuthService.getToken();
    const url = `${ENV.Infraestructure_API}/${mac}/device-profiles`;
    const headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };
    super(url, headers); 
    this.mac = mac;
    this.organization = organization;
  }

  getColumns(): any {
    return Columns(this.mac as string, this.organization as string);
  }

  async getEnums(): Promise<any> {
  }

  async processCsv(
    delimiter: string,
    serverFileName: string,
    aditionalData: any | null
  ): Promise<any> {
  }
}
