import { ENV } from "../../../utils";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";
import { Cache } from "../../interfaces.service";
import { CouncilColuns } from "./Columns";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";

export default class CouncilService
  extends AbstractCRUD<any>
{
  cache: Cache | undefined;
  uploadEndpoint: string;
  columns: IColumnForCRUD[];

  constructor() {
    const token = AuthService.getToken();
    const url = `${ENV.Auth_API}/council`;
    const headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };
    super(url, headers); // Llama al constructor de BaseService
    this.uploadEndpoint = `${url}/upload`;
    this.columns = CouncilColuns;
  }

  getColumns(): IColumnForCRUD[] {
    return this.columns;
  }

  async getEnums(): Promise<any> {
    // Implementación específica para obtener enums
  }

  async processCsv(
    delimiter: string,
    serverFileName: string,
    aditionalData: any | null
  ): Promise<any> {
    // Implementación para procesar CSV
  }

}
