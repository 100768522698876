import { useState, useEffect } from "react";
import { Modal, Form, Select, message } from "antd";
import { ENV } from "../../utils";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import RemoveButton from "../CommonTable/RemoveButton";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const ModalCNO = ({ visible, onCancel, element }) => {
  const [cnoList, setCnoList] = useState([]);
  const [councilList, setCouncilList] = useState([]);
  const [networkServerList] = useState([
    { gatewayMac: useLocation().pathname.split("/")[4] },
  ]);
  const [routeCNO] = useState(`${ENV.Auth_API}/${ENV.API_ROUTES.CNO}`);
  const [cnoService] = useState(new BasicApi(routeCNO));
  const [councilService] = useState(
    new BasicApi(`${ENV.Auth_API}/${ENV.API_ROUTES.COUNCIL}`),
  );

  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();

  // CNO
  const handleOnRemove = async () => {};
  //Council
  //NS
  //const routeNetworkServer = `${ENV.Infraestructure_API}/${ENV.API_ROUTES.NETWORKSERVER}`;
  //const apiNetworkServer = new BasicApi(routeNetworkServer)
  const onFormSubmit = async (values) => {
    // Hacer algo con los valores del formulario, por ejemplo, enviarlos a la API
    cnoService
      .addOne(values)
      .then((value) => message.success(`CNO created OK`))
      .catch((error) => message.error(error.message));
  };

  const onFinishFailed = async () => {};

  useEffect(() => {
    //getCNOs()
    cnoService
      .getAll()
      .then((value) => setCnoList(value.db.result))
      .catch((error) => {
        setCnoList([]);
        messageApi.open({
          type: "error",
          content: `Error while getting CNOs. ${error?.message}`,
        });
      });
    //getCouncils
    councilService
      .getAll()
      .then((value) => setCouncilList(value.db.result))
      .catch((error) => {
        setCouncilList([]);
        messageApi.open({
          type: "error",
          content: `Error while getting councils. ${error?.message}`,
        });
      });
  }, [councilService, cnoService, messageApi]);

  return (
    <Modal
      open={visible}
      title={`${element.name}`}
      onCancel={onCancel}
      onOk={form.submit}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <h3>CNO List</h3>
          {cnoList.length > 0 ? (
            cnoList.map((item) =>
              item.OrganizationId === element.id ? (
                <div style={{ display: "flex", margin: "10px" }}>
                  <RemoveButton
                    route={routeCNO}
                    element={item}
                    onRemove={handleOnRemove}
                  />
                  <div style={{ margin: "10px" }}>
                    <li key={item.NsId}>NS: {item.NsId}</li>
                    <li key={item.OrganizationId}>Org: {element.name}</li>
                    <li key={item.CouncilId}>Council: {item.CouncilId}</li>
                  </div>
                </div>
              ) : (
                <></>
              ),
            )
          ) : (
            <></>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <h3>Formulario</h3>
          <Form
            form={form}
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="councilId" label="Council">
              <Select>
                {councilList.map((council) => (
                  <Option key={council.id} value={council.id}>
                    {council.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="nsId" label="N Server">
              <Select>
                {networkServerList.map((networkServer) => (
                  <Option
                    key={networkServer.gatewayMac}
                    value={networkServer.gatewayMac}
                  >
                    {networkServer.gatewayMac}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="organizationId" label="Org.">
              <Select>
                {[element].map((organization) => (
                  <Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCNO;
