import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import { ENV } from "../../utils/index.js";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

function ServiceProfileSelector({ element, dataName, title, form, required }) {
  const { t } = useTranslation();

  const [serviceProfile, setServiceProfile] = useState([]);
  const [location] = useState(useLocation());
  const [gatewayMac] = useState(location.pathname.split("/")[4]);
  const [serviceProfileService] = useState(
    new BasicApi(`${ENV.Infraestructure_API}/${gatewayMac}/service-profiles`),
  );

  const onValueChange = (value) => {
    form.setFieldValue(dataName, value);
  };

  // generateFormOptions
  useEffect(() => {
    serviceProfileService.getAll().then((value) => {
      const organizationSelected = location.pathname.split("/")[6];
      const data = value.db.result.filter(
        (item) =>
          item.topic === gatewayMac &&
          item.organizationID === organizationSelected,
      );
      setServiceProfile(data);
    });
  }, [serviceProfileService, location, gatewayMac]);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[{ required: required }]}
    >
      <>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={serviceProfile.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          onChange={onValueChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </>
    </Form.Item>
  );
}

export default ServiceProfileSelector;
