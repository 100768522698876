import { ENV } from "../../../utils/constants";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import axios from "axios";
//icons
import {
  IServiceCRUD,
  getAllForAlarms
} from "../../interfaces.service";

import {IColumnForCRUD} from "../../../components/DynamicTable/Interfaces/ColumnConfig"
export default class ControlCabinetService implements IServiceCRUD, getAllForAlarms {
  enums = null;
  url: string = "";
  token: string = "";
  constructor() {
    this.url = `${ENV.Streetlight_API}/controlCabinet`;
    this.token = AuthService.getToken();
  }
  async processCsv(delimiter: string = ",",serverFileName: string,aditionalData: any = null,) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const path: string = `${this.url}/process_csv`;
    const body: string = JSON.stringify({
      ...aditionalData,
      delimiter: delimiter,
      file_name: serverFileName,
    });

    return await fetch(path, { method: "POST", headers: headers, body: body })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async delete(id: string | string[] | number | number[]) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    id = Array.isArray(id) ? id.join(","):id;
    return await fetch(`${this.url}/${id}`, { method: "DELETE", headers: headers })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async getEnums() {
    if (this.enums) return this.enums;

    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};

    this.enums = await fetch(`${this.url}/enum`, { method: "GET", headers: headers })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
        return {};
      });
    return this.enums;
  }

  async getAll(filter: any, currentPage: number, pageSize: number) {
    let tmp_filters = { ...filter };
    Object.keys(filter).map((item) => {
      if (!tmp_filters[item] || tmp_filters[item] === "")
        delete tmp_filters[item];
      return null;
    });
    const offset = (currentPage - 1) * pageSize;
    const path: string = `${this.url}?filters=${JSON.stringify(tmp_filters)}&limit=${pageSize}&offset=${offset}`;
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};

    let result = await fetch(path, { method: "GET", headers: headers })
      .then((response) => response.json())
      .then((response) => {
        response.data = response.data.map((item: any) => ({ key: item.id, ...item }));
        return response;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
    return result;
  }
  async create(body: any = {}) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    return await fetch(this.url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async update(id: string | number, body = {}) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    return await fetch(`${this.url}/${id}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async getById(id: number | string | null) {
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await fetch(`${this.url}/${id}`, { method: "GET", headers: headers })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  async getAllForAlarms(filters: any = {},  currentPage: number = 1,pageSize: number = 10 ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const response:any = await axios
      .get(`${this.url}/getAllForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, { headers: headers })
      .then((response) => response.data);
    return response
  }
  getColumns(): IColumnForCRUD[] {
    return [
      {title: "lastSeen",dataIndex: "lastSeen",type: "lastSeen",visible: true, editable:false},
      {title: "id platform",dataIndex: "id_platform",type: "text",visible: true},
      {title: "latitude",dataIndex: "latitude",type: "float",visible: true},
      {title: "longitude",dataIndex: "longitude",type: "float",visible: true},
      {title: "poblacion",dataIndex: "poblation",type: "text",visible: true},
    ];
  }
}
