import React, { useEffect, useState } from "react";
import PublishSection from "./PublishSection";
import SubscribeSection from "./SubscribeSection";
import { Button, Card, message } from "antd";
import MqttClientService from "../../services/moduleLora/MqttClient/MqttClient.service.tsx";
import { useParams } from "react-router-dom";
import { ENV } from "../../utils/constants.js";
import AuthService,{ posibleRoles } from "../../services/moduleAuth/Auth/Auth.service";

export function MqttClient({ element, isCentralized = false }) {
  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const topic = useParams();

  useEffect(() => {
    /** TODO: remove just debug */
    if (socket) {
      socket.onclose = function (evt) {
        setIsConnected(false);
        messageApi.open({
          type: "success",
          content: "Close socket",
        });
      };
    }
  }, [socket]);

  const handleConnectToMqtt = async () => {
    try {
      setIsLoading(true);

      let response = await new MqttClientService().connect(
        JSON.stringify(topic)
      );
      if (response.status === 200) {
        const newSocket = new WebSocket(ENV.MqttWebsocket_URL);
        newSocket.onopen = () => {
          setIsConnected(true);
          setIsLoading(false);
          setSocket(newSocket);
          messageApi.open({
            type: "success",
            content: "Connecting socket",
          });
        };

        messageApi.open({
          type: "success",
          content: response?.data?.message,
        });
      } else {
        setIsConnected(false);
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message,
      });
      setIsLoading(false);
      return error;
    }
  };

  const handleDisConnectToMqtt = async () => {
    try {
      setIsLoading(true);
      let response = await new MqttClientService().disconnect();
      if (response.status === 200) {
        const newSocket = new WebSocket(ENV.MqttWebsocket_URL);
        newSocket.onclose = () => {
          setIsConnected(false);
          setSocket(null);
          setIsLoading(false);
        };

        messageApi.open({
          type: "error",
          content: response?.data?.message,
        });
      } else {
        setIsConnected(false);
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const handlePublishTopic = async (payload) => {
    try {
      let response = await new MqttClientService().publish(payload);
      if (response.status === 200) {
        messageApi.open({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const handleSubscribe = async (payload) => {
    try {
      let response = await new MqttClientService().subscribe(payload);

      if (response.status === 200) {
        messageApi.open({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  return (
    <div className="mt-4 ">
      <Button
        danger={isConnected}
        loading={isLoading}
        iconPosition={"left"}
        style={{ background: !isConnected && "#412157", width: "100%" }}
        size="middle"
        onClick={!isConnected ? handleConnectToMqtt : handleDisConnectToMqtt}
        className="mb-3"
      >
        {isConnected ? "DISCONNECT" : "CONNECT MQTT"}
      </Button>
      <Card>
        {contextHolder}
        <div className="header d-flex justify-content-end align-items-center"></div>
        <div className="row">
          {AuthService.atLeastRole(posibleRoles.ADMIN_COUNCIL) ? (
            <PublishSection
              isCentralized={isCentralized}
              element={element}
              isConnected={isConnected}
              socket={socket}
              handlePublishTopic={handlePublishTopic}
            />
          ) : (
            <></>
          )}
          <SubscribeSection
            element={element}
            socket={socket}
            isConnected={isConnected}
            handleSubscribe={handleSubscribe}
          />
        </div>
      </Card>
    </div>
  );
}
