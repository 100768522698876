import { useState, useEffect, ReactElement } from "react";
import { Flex, Modal, Select, message } from "antd";
import { Pagination } from "antd";
import { IServiceCRUD, } from "../../services/interfaces.service";
import { IColumnForCRUD } from "../DynamicTable/Interfaces/ColumnConfig";
import { ModalParams } from "./ModalParams";
import EnumColumnType from "./EnumColumnType/EnumColumnType";
import TextColumnType from "./TextColumnType/TextColumnType";
import BoolColumnType from "./BoolColumnType/BoolColumnType";
import ServiceColumnType from "./ServiceColumnType/ServiceColumnType";

export default function AddOrEditEntityModal({title = "",id = "",service,isModalOpen,setIsModalOpen,paramsPerPage = 5,callback = () => {},type,}: ModalParams) {
  const [entity, setEntity] = useState<any>({  });
  const [enumOptions, setEnumOptions] = useState<any>({});
  const [rowsWithInput, setRowsWithInput] = useState<ReactElement[]>([]);
  const [pagination, setPagination] = useState<number>(1);
  //
  // MODAL HANDLERS 
  //
  const handleOk = async () => {
    const data = { ...entity};
    try {
      switch (type.toLocaleLowerCase()) {
        case "edit":
          await service.update(id, data);
          message.success("Edited successfully");
          break;
        case "add":
          await service.create(data);
          message.success("Created successfully");

          break;
      }
    } catch (error) {
      message.error("Error: " + error);
      console.log("error", error);
    }
    callback.constructor.name === "AsyncFunction" ? await callback() : callback();
    //setEntity({});
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setEntity({});
    setIsModalOpen(false);
  };
  const processColumnType = async (column: IColumnForCRUD) =>{
    let result: any = {};
    result.title = column.title || "";
    result.dataIndex = column.dataIndex;
    switch (column.type) {
      case "enum":
        const options = column.subtype && typeof column.subtype === "string" ? enumOptions[column.subtype] : [];
        result.inputType = ( <EnumColumnType column ={column} options={options} entity={entity} setEntity={setEntity} />);
        break;
      case "text":
        result.inputType = ( <TextColumnType column ={column} entity={entity} setEntity={setEntity} /> );
        break;
      case "float":
        result.inputType = ( <TextColumnType column ={column} entity={entity} setEntity={setEntity} /> );
        break;
      case "number":
        result.inputType = ( <TextColumnType column ={column} entity={entity} setEntity={setEntity} /> );
        break;
      case "boolean":
        result.inputType = ( <BoolColumnType column ={column} entity={entity} setEntity={setEntity} /> );
        if( entity[column.dataIndex] === undefined ) entity[column.dataIndex] = true;
        break;
      case "date":
        result.inputType = ( <TextColumnType column ={column} entity={entity} setEntity={setEntity} /> );
        break;
      case "service":
        const service: IServiceCRUD = column.service ;
        let optionsAnotherTable: any;
        
        if( column.defaultValue && !entity[column.dataIndex] ) {
          console.log("HOLAAA",column.defaultValue, entity[column.dataIndex] )
          setEntity({ ...entity, [column.dataIndex]: column.defaultValue });
        }
        
        optionsAnotherTable = service.cache ? service.cache : await service.getAll({},1,0).then((response: any) => response);
        optionsAnotherTable = optionsAnotherTable.db?.result ? optionsAnotherTable.db.result : optionsAnotherTable.data
        optionsAnotherTable = optionsAnotherTable.map((item: any) => ({ label: item[column.serviceLabel || 'name'], value: item.id, }));

        result.inputType = (<ServiceColumnType column ={column} entity={entity} setEntity={setEntity}  />);
        break;

      // TODO: check it with enum in service 
      case "roleSelector":
        result.inputType = (
          <Select
            style={{ width: "200px" }}
            value={entity[column.dataIndex] || entity["userRole"]}
            onChange={(value) =>
              setEntity({ ...entity, [column.dataIndex]: value })
            }
            options={[
              "ADMIN_TECH",
              "SUPER_ADMIN",
              "USER_COUNCIL",
              "ADMIN_COUNCIL",
            ].map((value) => ({
              label: value,
              value: value,
            }))}
          />
        );
        break;
    }
    return result
  }
  async function processColumn(data: IColumnForCRUD[]) {
    let totalResult = [];
    for (let column of data) {
      if ("editable" in column && !column.editable) continue;
      const result = await processColumnType(column);
      totalResult.push(result);
    }
    return totalResult;
  }
  //
  // GET DATA IN SETUP
  //
  useEffect(() => {
    if (!service || !isModalOpen) return void 0;
    if (type.toLowerCase() === "edit") {
      service.getById(id).then((data) => {
        setEntity(data)
      })
        .catch((error) => {
          setEntity([]);
          message.error('Error getting data')
          console.log("error", error);
        });

    }
    service.getEnums().then((data) => setEnumOptions(data))
      .catch((error) => {
        setEnumOptions({});
        message.error('Error getting enum data')
        console.log("error", error);
      });
  }, [isModalOpen, service, type, id]);
  //
  // GET DATA TO SHOW
  //
useEffect(() => {
  const columnInPage = service.getColumns().filter(
    (column: IColumnForCRUD) =>
      !Object.keys(column).includes("editable") || column.editable
  )
  .slice((pagination - 1) * paramsPerPage)
  .slice(0, paramsPerPage);

  processColumn(columnInPage).then((data: any) => {
    let tmp: any = data.map((data: any) => {
      if (!data) return null; 
      return (
        <Flex justify="space-between" style={{ width: "500px" }} key={data.dataIndex} >
          {data.title}
          {data.inputType}
        </Flex>
      );
    });
    setRowsWithInput(tmp);
  });
}, [entity, pagination, enumOptions, paramsPerPage, service]);

  return (
    <Modal width="1000px" title={`${title}`} okText="Save" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Flex style={{ minHeight: "300px" }}>
        <Flex vertical style={{  width: "100%", borderRadius: 6,}} justify={"center"} align={"center"}>
          {rowsWithInput}
        </Flex>
      </Flex>
      <Flex justify="center" align="center">
        <Pagination
          defaultPageSize={paramsPerPage}
          current={pagination}
          showSizeChanger={false}
          onChange={(page) => setPagination(page)}
          total={service ? service.getColumns().filter(column => column.editable === undefined || column.editable === true).length : 0}
        />
      </Flex>
    </Modal>
  );
}
