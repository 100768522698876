import { ENV } from "../../../utils";
import axios from "axios";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { Cache, IServiceCRUD } from "../../interfaces.service";
import { Columns } from "./Columns";

export default class SensorService implements IServiceCRUD {
  cache?: Cache | undefined;
  url: string;
  token: string;
  mac:string|undefined;
  headers: { "x-access-token": string; "Content-Type": string; };

  constructor(mac:string|undefined= undefined) {
    this.token = AuthService.getToken();
    this.mac = mac;
    this.url = `${ENV.Infraestructure_API}/${this.mac}/devices`;
    this.headers = { "x-access-token": this.token, "Content-Type": "application/json" };
  }

  getColumns() : any {
    return Columns(this.mac as string)
  }

  async getAll( filters: any,currentPage: number,pageSize: number): Promise<any> {
    const offset = (currentPage - 1) * pageSize;
    const rawData = await axios.get(
      `${this.url}?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`,
      { headers: this.headers }
    );
    this.cache = {
      data: rawData.data.db.result,
      totalCount: rawData.data.db.totalCount,
    }
    return this.cache;
  }

  async create(body: any): Promise<any> {
    return await axios.post(this.url, body, { headers: this.headers });
  }

  async getById(id: string | number | null): Promise<any> {
    const item = this.cache?.data.find((item: any) => item.id === id) ??
      await axios.get(`${this.url}/${id}`, { headers: this.headers })
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error:", error);
        return {};
      });
      return item
  }

  async update(id: string | number, body: any): Promise<any> {
    return await axios.put(`${this.url}/${id}`, body, { headers: this.headers });
  }

  async delete(id: string | string[] | number | number[]): Promise<any> {
    const IdsWithCommas = Array.isArray(id) ? id.join(",") : id;
    return await axios.delete(`${this.url}/${IdsWithCommas}`, {
      headers: this.headers,
    });
  }
  async getEnums() {}
}
