import { useEffect, useState } from "react";
import { FullDynamicTable } from "../../components/DynamicTable";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Flex, message, Space } from "antd";
import AddOrEditEntityModal from "../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { CRUDPrivilages } from "../../components/DynamicTable/Interfaces/CRUDPrivilages";
import { useParams } from "react-router-dom";
import SensorService from "../../services/moduleLora/Sensor/Sensor.service";
import ShowInfoModal from "./ShowInfoModal";

type params = { CRUDPrivilages: CRUDPrivilages };

export default function Sensor({ CRUDPrivilages }: params) {
  const { physicalGatewayId, organizationId } = useParams();
  const sensorService = new SensorService(physicalGatewayId)

  const [service, setService] = useState(sensorService);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deviceId, setDeviceId] = useState(0);
  const [refreshTable, setRefreshTable] = useState(true);

  const [modalInfoOpen, setModalInfoOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<any>({});

  useEffect(() => {
    setService(service);
  }, []);

  const columns = [
    ...service.getColumns(),
    {
      title: (
        <Flex align="center" justify="center">
          Actions
        </Flex>
      ),
      key: "actions",
      dataIndex:"actions",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text: string, record: any) => (
        <Flex justify="center">
          <Space align="center">
            {CRUDPrivilages.update && (
              <EditOutlined
                style={{ fontSize: "1.8em", color: "#7150CC" }}
                onClick={() => {
                  setDeviceId(record.devEUI);
                  setEditModal(true);
                }}
              />
            )}
             <ShowInfoModal open={modalInfoOpen} setOpen={setModalInfoOpen} data={modalInfo} />
            {CRUDPrivilages.delete && (
              <DeleteOutlined
                style={{ fontSize: "1.8em", color: "#DC4446" }}
                onClick={() => {
                  setDeviceId(record.devEUI);
                  setDeleteModal(true);
                }}
              />
            )}
          </Space>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <AddOrEditEntityModal
        title={`Sensor`}
        type="edit"
        service={service}
        id={deviceId}
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        paramsPerPage={10}
        callback={() => {
          setRefreshTable(!refreshTable);
        }}
      />

      <ConfirmationModal
        callback={async ()=>{ 
        if(await service.delete(deviceId)){
          setRefreshTable(!refreshTable);
          message.success("Deleted successfully!")
          }
        }}
        message="Are you sure you want to delete this sensor?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <FullDynamicTable
        title={`Sensor`}
        service={service}
        generalActions={{
          add: CRUDPrivilages.create ? {} : null,
          configColumns: {},
        }}
        columns={columns}
        multiSelect={true}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
      />
    </>
  );
}
