import axios from "axios";
import { Cache,IServiceCRUD } from "../../interfaces.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";



export abstract class AbstractCRUD<T>  implements IServiceCRUD{
  public url: string;
  public headers: Record<string, string>;
  public cache: Cache | undefined;

  constructor(url: string, headers: Record<string, string>) {
    this.url = url;
    this.headers = headers;
    this.cache = undefined; 
  }
  abstract getEnums(): Promise<any> 
  abstract getColumns(): IColumnForCRUD[] 

  async getAll(
    filters: any,
    currentPage: number,
    pageSize: number
  ): Promise<{ data: T[]; totalCount: number }> {
    const offset = (currentPage - 1) * pageSize;

    const response = await axios.get(
      `${this.url}?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`,
      { headers: this.headers }
    );

    this.cache = {
      data: response.data.db.result,
      totalCount: response.data.db.totalCount,
    };

    return this.cache;
  }

  async getById(id: string | number): Promise<T | null> {
    const cachedItem = this.cache?.data.find((item) => (item as any).id === id);
    if (cachedItem) {
      return cachedItem;
    }

    try {
      const response = await axios.get(`${this.url}/${id}`, { headers: this.headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching item by ID:", error);
      return null;
    }
  }

  async create(body: T): Promise<T> {
    const response = await axios.post(this.url, body, { headers: this.headers });
    return response.data;
  }

  async update(id: string | number, body: Partial<T>): Promise<T> {
    const response = await axios.put(`${this.url}/${id}`, body, { headers: this.headers });
    return response.data;
  }

  async delete(id: string | string[] | number | number[]): Promise<boolean> {
    const IdsWithCommas = Array.isArray(id) ? id.join(",") : id;
    const response = await axios.delete(`${this.url}/${IdsWithCommas}`, { headers: this.headers });
  
    if (response.status === 200) {
      if (this.cache) {
        this.cache.data = this.cache.data.filter((item) => (item as any).id !== id);
      }
      return true;
    }
    return false;
  }
  
}
