import { Button, Form, Typography, message } from "antd";
import { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Hooks";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import { atom } from "jotai";
import moment from "moment";
import BooleanInput from "./BooleanInput.component";

const { Text } = Typography;

export const enableInputGroupAtom = atom({ streetlightType: 0 });
const CommonForm = ({
  element,
  attributes,
  submitButton,
  route,
  modalClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const SubmitButton = submitButton;
  const [messageApi, contextHolder] = message.useMessage();
  const { user, getUser } = useAuth();

  // --------------------------------------------
  // Alert created timer
  const [userData, setUserData] = useState([]);

  const onFinish = (values) => {
    // If there is no element then use POST
    const api = new BasicApi(route);
    for (let index = 0; index < attributes.length; index++) {
      if (attributes[index].formItem === BooleanInput)
        values[attributes[index].key] = values[attributes[index].key]
          ? true
          : false;
    }
    if (!element) {
      getUser();
      values["user_creator"] = user.userId;
      values["user_last_modificated"] = user.userId;
      let valuesToSend = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => {
          return value !== null;
        }),
      );
      api
        .addOne(valuesToSend)
        .then(() => {
          setUserData(valuesToSend);
          messageApi.open({
            type: "success",
            content: t("Element created."),
          });
        })
        .catch((error) => {
          messageApi.open({
            type: "error",
            content: t(error.message),
          });
          return;
        });
    }
    // If there is an element use PUT to update it
    else {
      values["user_last_modificated"] = user.userId;
      let valuesToSend = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => {
          return value !== null;
        }),
      );
      api
        .editOne(valuesToSend, element.id)
        .then(() => {
          messageApi.open({ type: "success", content: "Element edited." });
        })
        .catch((error) => {
          messageApi.open({
            type: "error",
            content: t("Error when editing new element."),
          });
          return;
        });
    }
    // Close the modal
    modalClose();
  };
  // --------------------------------------------

  const onFinishFailed = (errorInfo) => {};
  if (element !== undefined) {
    const date_regex =
      /^\d{4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}\.\d{3}Z$/;
    Object.entries(element).forEach(([key, value]) => {
      if (typeof value === "string" && value.match(date_regex)) {
        element[key] = moment(value);
      }
    });
  }

  return (
    <>
      {contextHolder}
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form
            initialValues={element}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            {element && <Text>ID: {element.id}</Text>}

            {attributes
              .filter((attr) => attr.formItem)
              .map((attr) => {
                const Component = attr.formItem;
                return (
                  <Component
                    route={route}
                    element={element ? element : {}}
                    required={attr.required ? attr.required : false}
                    options={attr.options ? attr.options : []}
                    dataName={attr.key}
                    title={attr.name}
                    form={form}
                    child={attr.child ? attr.child : {}}
                    textWidth={attr.textWidth ? attr.textWidth : undefined}
                    disabledVarName={
                      Object.keys(attr).includes("disabledVarName")
                        ? attr.disabledVarName
                        : null
                    }
                    disabledTargetValue={
                      Object.keys(attr).includes("disabledTargetValue")
                        ? attr.disabledTargetValue
                        : null
                    }
                    disabledVarMaxValue={
                      Object.keys(attr).includes("disabledVarMaxValue")
                        ? attr.disabledVarMaxValue
                        : null
                    }
                  />
                );
              })}

            {submitButton ? (
              <SubmitButton userData={userData} />
            ) : (
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Submit")}
                </Button>
              </Form.Item>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CommonForm;
