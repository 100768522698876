import React from "react";
import { Button, Form, Modal, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IColumnForCRUD } from "../Interfaces/ColumnConfig";

interface params {
  allAttributes_raw: IColumnForCRUD[];
  callback: (result: IColumnForCRUD[]) => void;
  disabled?: boolean;
}
export default function ConfigColumns({allAttributes_raw,callback,disabled = false}: params) {
  const { t } = useTranslation();
  let [allAttributes, setAllAttributes] = useState<any[]>([]);
  let [attributeSelected, setAttributeSelected] = useState<IColumnForCRUD[]>([]);
  useEffect(() => {
    setAllAttributes(
      allAttributes_raw
        .filter((e) => e.dataIndex !== "actions")
        .map((item:IColumnForCRUD) => {
          return {
            label: t(item.title as string),
            value: item.dataIndex,
            visible: item.visible,
          };
        }),
    );
  }, [allAttributes_raw, t]);
  // Modal view controls
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setAttributeSelected(
      allAttributes
      .filter((e) => !Object.keys(e).includes("visible") || e.visible)
      .map((e) => e.value)
    )
  }, [allAttributes]);

  const onCancel = () => {
    setAllAttributes(
      allAttributes_raw
        .filter((e) => e.dataIndex !== "actions")
        .map((item) => ({ label: item.title, value: item.dataIndex }))
    );
    setIsModalOpen(false);
  };
  const onSave = () => {
    let result = [...allAttributes_raw].map((item: any) => {
      if (item.key === "actions") return item;
      return { ...item, visible: attributeSelected.includes(item.dataIndex) };
    });
    setIsModalOpen(false);
    callback(result)
  };

  return (
    <>
      <Button
        style={{ margin: "6px" }}
        className="buttonCentered"
        disabled={disabled}
        onClick={() => setIsModalOpen(true)}
      >
        <SettingOutlined
          onPointerOverCapture={undefined}
          onPointerMoveCapture={undefined}
        />
      </Button>
      <Modal
        open={isModalOpen}
        title={t("Shown attributes")}
        okText={"Save"}
        onOk={() => {
          onSave();
        }}
        onCancel={onCancel}
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            key={"attributes"}
            label={t("Attributes shown")}
            name={"attributes"}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              value={attributeSelected}
              onChange={(value) => setAttributeSelected(value)}
              defaultValue={attributeSelected}
              options={allAttributes}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
