import { ENV } from "../../../utils";
import axios from "axios";
import { getAllForAlarms } from "../../interfaces.service";
import AuthService from "../../moduleAuth/Auth/Auth.service";

export default class DeviceService implements getAllForAlarms {
  url: string = `${ENV.Infraestructure_API}`;
  token: string;
  constructor() {
    this.token = AuthService.getToken();
  }
  async getAll(topic: string) {
    const headers: any = {
      "x-access-token": this.token,
      "Content-Type": "application/json",
    };
    return await axios.get(
      `${ENV.Infraestructure_API}/${topic}/devices`,
      { headers: headers },
    );
  }

  async getAllForAlarms(filters: any = {},  currentPage: number = 1,pageSize: number = 10 ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const response:any = await axios
      .get(`${ENV.Infraestructure_API}/devicesForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, { headers: headers })
      .then((response) => response.data);
    return response
  }
}
