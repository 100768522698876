import { Button, Input, Modal, Select, Tabs } from "antd";
import { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Table } from "antd";
import {
  checkData,
  columns,
} from "../../../../../components/TableShowTwoColumns/TableShowTwoColumns";
import StaService from "../../../../../services/moduleSTA/Sta/Sta.service";
function ShowEvents(dev_EUI) {
  const [staService] = useState(new StaService());
  let [eventFilter, setEventFilter] = useState({
    typeEvent: [
      "creation",
      "salt",
      "interval",
      "counter",
      "firmware",
      "connection",
    ],
    date_regex: "",
    date_sort: true,
  });
  let [events, setEvents] = useState([]);
  useEffect(() => {
    if (dev_EUI)
      staService.getEvents(dev_EUI).then((data) =>
        setEvents(
          data
            .filter((item) => eventFilter.typeEvent.includes(item.eventType))
            .filter((item) => item.created_at.match(eventFilter.date_regex))
            .sort((a, b) =>
              eventFilter.date_sort
                ? new Date(a.created_at) - new Date(b.created_at)
                : new Date(b.created_at) - new Date(a.created_at),
            ),
        ),
      );
  }, [dev_EUI, eventFilter, staService]);

  return (
    <Table dataSource={events} scroll={{ x: 50, y: 350 }}>
      <Table.Column title="Message" dataIndex="msg" key="msg" />
      <Table.Column
        title={
          <>
            Tipo de evento
            <Select
              options={[
                "creation",
                "salt",
                "interval",
                "counter",
                "firmware",
                "connection",
              ].map((item) => ({ label: item, value: item }))}
              style={{ width: "200px" }}
              mode="multiple"
              value={eventFilter.typeEvent}
              onChange={(value) => {
                setEventFilter({ ...eventFilter, typeEvent: value });
              }}
            ></Select>
          </>
        }
        dataIndex="eventType"
        key="eventType"
      />

      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title={
          <>
            Date
            <Input
              value={eventFilter.date_regex}
              onChange={(e) => {
                setEventFilter({ ...eventFilter, date_regex: e.target.value });
              }}
            />
            <Button
              onClick={() =>
                setEventFilter({
                  ...eventFilter,
                  date_sort: !eventFilter.date_sort,
                })
              }
            >
              Sort
            </Button>
          </>
        }
      />
    </Table>
  );
}
function ShowGenral(data) {
  return (
    <Table
      dataSource={data}
      pagination={{ pageSize: 5 }}
      scroll={{ x: 50, y: 350 }}
      columns={columns}
    />
  );
}
export function ModalStaInfo({ originalData = {}, modalOpen, setModalOpen }) {
  let [sta, setSta] = useState({});
  useEffect(() => {
    setSta(originalData);
  }, [originalData]);

  const _sta = { ...sta };
  delete _sta["buffer"];
  const dataTable = checkData(_sta);
  const items = [
    {
      key: "1",
      label: "General",
      children: ShowGenral(dataTable),
    },
    {
      key: "2",
      label: "History of events",
      children: ShowEvents(originalData.dev_EUI),
    },
  ];
  return (
    <Modal
      width={1000}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={() => setModalOpen(false)}
    >
      <Title>{originalData.name}</Title>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={() => {
          console.log(originalData);
        }}
      />
    </Modal>
  );
}
