
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
import NetworkServerService from "../NetworkServer/NetworkServer.service";
import OrganizationService from "../Organization/Organization.service";

export const Columns = (mac: string,organization: string): IColumnForCRUD[] => {
    return [
        { dataIndex: 'name', title: 'Name', type: 'text', },
        { dataIndex: 'networkServerID', title: 'Network Server ID', type: 'service', service: new NetworkServerService(),defaultValue: mac, serviceLabel: "gatewayMac", disabled:true },
        { dataIndex: 'organizationID', title: 'Organization ID', type: 'service', service: new OrganizationService(mac), defaultValue: organization, disabled:true , serviceLabel: "name", serviceValue: "id" },
        { dataIndex: 'addGWMetaData', title: 'Add GW Meta Data', type: 'boolean' },
        { dataIndex: 'channelMask', title: 'Channel Mask', type: 'text', visible: false  },
        { dataIndex: 'devStatusReqFreq', title: 'Dev Status Req Freq', type: 'number', visible: false,  },
        { dataIndex: 'dlBucketSize', title: 'DL Bucket Size', type: 'number', visible: false,  },
        { dataIndex: 'dlRate', title: 'DL Rate', type: 'number', visible: false,  },
        { dataIndex: 'dlRatePolicy', title: 'DL Rate Policy', type: 'text', visible: false,  },
        { dataIndex: 'drMax', title: 'DR Max', type: 'number', visible: false,  },
        { dataIndex: 'drMin', title: 'DR Min', type: 'number', visible: false,  },
        { dataIndex: 'gwsPrivate', title: 'GWs Private', type: 'boolean', visible: false,  },
        { dataIndex: 'hrAllowed', title: 'HR Allowed', type: 'boolean', visible: false,  },
        { dataIndex: 'nwkGeoLoc', title: 'NWK Geo Loc', type: 'boolean', visible: false,  },
        { dataIndex: 'prAllowed', title: 'PR Allowed', type: 'boolean', visible: false,  },
        { dataIndex: 'raAllowed', title: 'RA Allowed', type: 'boolean', visible: false,  },
        { dataIndex: 'reportDevStatusBattery', title: 'Report Dev Status Battery', type: 'boolean', visible: false,  },
        { dataIndex: 'reportDevStatusMargin', title: 'Report Dev Status Margin', type: 'boolean', visible: false,  },
        { dataIndex: 'minGWDiversity', title: 'Min GW Diversity', type: 'number', visible: false,  },
        { dataIndex: 'targetPER', title: 'Target PER', type: 'number', visible: false,  },
        { dataIndex: 'ulBucketSize', title: 'UL Bucket Size', type: 'number', visible: false,  },
        { dataIndex: 'ulRate', title: 'UL Rate', type: 'number', visible: false,  },
        { dataIndex: 'ulRatePolicy', title: 'UL Rate Policy', type: 'text', visible: false,  },
    ];
}