import { ENV } from "../../../utils";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { Columns } from "./Columns";
import { IServiceCRUD, getAllForAlarms, uploadCSV, } from "../../interfaces.service";
import axios from "axios";


export default class StreetlightService implements IServiceCRUD, getAllForAlarms,uploadCSV {
  url: string;
  enums = null;
  ControlCabinetId: number;
  token: string;
  uploadEndpoint:string
  constructor(ControlCabinetId: number) {
    this.ControlCabinetId = ControlCabinetId;
    this.url = `${ENV.Streetlight_API}/controlCabinet/${ControlCabinetId}/streetlight`;
    this.uploadEndpoint =  `${ENV.Streetlight_API}/upload/csv`
    this.token = AuthService.getToken();
  }
  async processCsv(delimiter: string = ",",serverFileName: string,aditionalData: any = null) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const path: string = `${this.url}/process_csv`;
    const body: string = JSON.stringify({...aditionalData,delimiter: delimiter,file_name: serverFileName});
    return await fetch(path, { method: "POST", headers: headers, body: body })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  async getEnums() {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    if (this.enums) return this.enums;
    this.enums =  await fetch(`${ENV.Streetlight_API}/enum`,{method: 'GET',headers: headers})
        .then(response => response.json())
        .catch((error)=>{console.log('Error:', error);return {}})
    return this.enums
  }
  async getAll(filter: any, currentPage: number,pageSize: number ) {
    let tmp_filters = { ...filter };
    Object.keys(filter).map((item) => {
      if (!tmp_filters[item] || tmp_filters[item] === "")
        delete tmp_filters[item];
      return null;
    });
    const offset = (currentPage - 1) * pageSize;
    const path: string = `${this.url}?filters=${JSON.stringify(tmp_filters)}&limit=${pageSize}&offset=${offset}`;
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    let result = await fetch(path, { method: "GET", headers: headers })
      .then((response) => response.json())
      .then((response) => {
        response.data = response.data.map((item: any) => ({ key: item.id, ...item }));
        return response;
      })
      .catch((error) => {console.log(error);return []});
    return result;
  }
  async getAllForAlarms(filters: any = {},  currentPage: number = 1,pageSize: number = 10 ) {
    const headers: any = {"x-access-token": this.token,"Content-Type": "application/json"};
    const offset = (currentPage-1) * pageSize;
    const response:any = await axios
      .get(`${this.url}/getAllForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`, { headers: headers })
      .then((response) => response.data);
    return response
  }

  async create(body:any={}) {
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      return await fetch(this.url,{ method: 'POST',headers: headers,body: JSON.stringify(body)})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);})
  }
  async update(id:number|string="", body:any={}) {
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      return await fetch(`${this.url}/${id}`,{ method: 'PUT',headers: headers,body: JSON.stringify(body)})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);})
  }
  async delete(ids:number|string|number[]|string[]) {
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      ids = Array.isArray(ids)? ids.join(","):ids.toString()
      return await fetch(`${this.url}/${ids}`,{ method: 'DELETE',headers: headers})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);})
  }
  async getById(id:string|number|null){
      const headers:any =  {'x-access-token': this.token,'Content-Type': 'application/json'}
      return await fetch(`${this.url}/${id}`,{ method: 'GET',headers: headers})
          .then(response => response.json())
          .catch((error)=>{console.log('Error:', error);return {}})
  }

  getColumns() : any {
    return Columns()
  }
}
