import React from "react";
import {
  Col,
  DatePicker,
  Flex,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import EmailInput from "../../../../../components/EmailInputs/EmailInputs";
import { AlarmInterface } from "../../../../../services/moduleAlarms/Alarms/Alarm.interfaces";

export type AlarmOptionType = {
   "connection"?:any;
   "aforo"?:{options:string[]};
   "value"?:{options:string[]};
};
type params = {
  alarmEntity: AlarmInterface;
  setAlarmEntity: Function;
  optionTypes?:AlarmOptionType;
};
export function AlarmSetting({ 
    alarmEntity, 
    setAlarmEntity,
    optionTypes = {connection:{}, },
 }: params) {
  return (
    <>
      <Row>
        <Col span={8}>
          <Flex
            align="center"
            justify="space-between"
            style={{ width: "80%", padding: "10px" }}
          >
            Name:
            <Input
              value={alarmEntity.name}
              onChange={(value) => {
                setAlarmEntity({ ...alarmEntity, name: value.target.value });
              }}
              style={{ width: 115 }}
            />
          </Flex>
        </Col>
        <Col span={16}>
          <Flex
            align="center"
            justify="space-between"
            style={{ width: "100%", padding: "10px" }}
          >
            Description:
            <Input
              value={alarmEntity.description}
              onChange={(value) => {
                setAlarmEntity({
                  ...alarmEntity,
                  description: value.target.value,
                });
              }}
              style={{ width: 390 }}
            />
          </Flex>
        </Col>
        <Col span={8}></Col>
      </Row>

      <Row>
        <Col span={8}>
          Alarm Type:
          <Select
            options={Object.keys(optionTypes).map((item: any) => ({value:item, label:item}))}
            onChange={(value) => {
              setAlarmEntity({ ...alarmEntity, alarmType: value });
            }}
            value={alarmEntity.alarmType}
            style={{ width: "150px" }}
          />
        </Col>
        {["value", "aforo"].includes(alarmEntity.alarmType) && (
          <>
            <Col span={6}>
              {alarmEntity.alarmType} type:
              <Select
                options={optionTypes[alarmEntity.alarmType].options.map((item: any)  => ({value:item, label:item}))}
                value={alarmEntity.variableName}
                onChange={(value) => {
                  setAlarmEntity({ ...alarmEntity, variableName: value });
                }}
                style={{ width: "100px" }}
              />
            </Col>
            <Col span={6}>
              {alarmEntity.alarmType} value:
              <InputNumber
                min={1}
                max={999999999}
                value={alarmEntity.variableValue}
                defaultValue={1}
                onChange={(value) => {
                  setAlarmEntity({ ...alarmEntity, variableValue: value });
                }}
              />
            </Col>
          </>
        )}
        {alarmEntity.alarmType === "connection" && (
          <Col span={8}>
            Deactive hours:
            <InputNumber
              min={1}
              max={1000}
              value={alarmEntity.deactive_hours}
              onChange={(value) => {
                setAlarmEntity({ ...alarmEntity, deactive_hours: value });
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col span={8}>
          <Flex
            align="center"
            justify="space-between"
            style={{ width: "80%", padding: "10px" }}
          >
            startDate:
            <DatePicker
              onChange={(value) => {
                setAlarmEntity({ ...alarmEntity, start_date: value });
              }}
              value={alarmEntity.start_date}
              style={{ width: 115 }}
            />
          </Flex>
        </Col>

        <Col span={8}>
          <Flex
            align="center"
            justify="flex-end"
            style={{ width: "100%", padding: "10px", justifyContent: "center" }}
          >
            Enable:
            <Flex justify="center">
              <Switch
                //justify="center"
                checked={alarmEntity.enable}
                onChange={(value) => {
                  setAlarmEntity({ ...alarmEntity, enable: value });
                }}
                style={{ padding: "10px", margin: "0 10px" }}
              />
            </Flex>
          </Flex>
        </Col>
      </Row>
      <Col>
        <EmailInput setAlarmEntity={setAlarmEntity} alarmEntity={alarmEntity} />
        <p>Subject:</p>
        <Input.TextArea
          showCount
          maxLength={100}
          value={alarmEntity.email_subject}
          onChange={(value) => {
            setAlarmEntity({
              ...alarmEntity,
              email_subject: value.target.value,
            });
          }}
        />
        <p>Body:</p>
        <Input.TextArea
          showCount
          maxLength={100}
          value={alarmEntity.email_body}
          onChange={(value) => {
            setAlarmEntity({ ...alarmEntity, email_body: value.target.value });
          }}
          style={{ height: 120, resize: "none", margin: "0 0 10px" }}
        />
      </Col>
    </>
  );
}
