import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import { ENV } from "../../utils";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

function OrganizationSelector({ element, dataName, title, form }) {
  const [location] = useState(useLocation());
  const [gatewayMac] = useState(location.pathname.split("/")[4]);
  const [thisOrganization] = useState(location.pathname.split("/")[6]);
  const [organizationService] = useState(
    new BasicApi(
      `${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.ORGANIZATIONS}`,
    ),
  );

  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const onValueChange = (value) => {
    form.setFieldValue(dataName, value);
  };

  //generateFormOptions
  useEffect(() => {
    // Get all data for selector
    organizationService.getAll().then((value) => {
      value.db.result.map((organization) => {
        if (organization.id === thisOrganization) setData([organization]);
        return null;
      });
    });
  }, [organizationService, thisOrganization]);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <>
        <Select
          showSearch
          style={{
            width: 200,
          }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={data.map((organization) => ({
            label: organization.name,
            value: organization.id,
          }))}
          onChange={onValueChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </>
    </Form.Item>
  );
}

export default OrganizationSelector;
