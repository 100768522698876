import { ENV } from "../../../utils";
import axios from "axios";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { IServiceCRUD } from "../../interfaces.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
export default class SensorWithoutTopicService implements IServiceCRUD {
  token: string;
  url: string 
  constructor(topic = "") {
    this.token = AuthService.getToken();
    this.url = `${ENV.Infraestructure_API}/${topic}/devices`; // to apply in CRUD methods
  }
  // <TODO>
  async create(body: any) {return null}
  
  async update(id: string | number, body: any) {return null};
  async delete(id: string | number | string[] | number[]) {return null};
  async getEnums() {return null}
  
  async getAllIds(){return []}
  // </TODO>
  async getById(id: string | number | null){
    const headers = {headers: {"x-access-token": this.token}};
    return await axios.get(`${ENV.Infraestructure_API}/devicesWithoutTopic/${id}`,headers)
                    .then(res => res.data)
                    .catch(err => console.log(err));
  };
  async getContextDataByObject({devEUI,applicationID,topic}: any) {
    const headers = {headers: {"x-access-token": this.token}};
    let result=  await axios.get(`${ENV.Infraestructure_API}/devices/getContextDataByObject/${devEUI}/application/${applicationID}/topic/${topic}`,headers)
                            .then(res => res.data)
                            .catch(err => console.log(err));
    return result;
  }
  async getAll(filters: any,  currentPage: number,pageSize: number) {
    const headers = {headers: {"x-access-token": this.token}};
    const offset = (currentPage-1) * pageSize;
    let result=  await axios.get(`${ENV.Infraestructure_API}/devices/getAllWithoutTopic?searchBy=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`,headers)
                            .then(res =>  res.data)
                            .then(result => {
                              result.data = result.data.map((item: any) => ({...item, key: `${item.topic}-${item.devEUI}`}))
                              return result
                            })
                            .catch(err => {console.log(err); return { totalCount: 0, data: [] }});
    return result;
  };
  getColumns():IColumnForCRUD[]{return [
    { title: "name",dataIndex: "name",type: "text"},
    { title: "devEUI", dataIndex: "devEUI", type: "text" },
    { title: "lastSeen",dataIndex: "lastSeen",type: "lastSeen",lastSeenDiff: "1h",editable: false},
    { title: "Network Server",dataIndex: "topic",type: "text"},
    { title: "physicalGateway_name",dataIndex: "pg___name",type: "text"},
    

  ]}
  async downloadCSV(filters: any) {
    try {
      const headers = {headers: {"x-access-token": this.token}};
      const response = await fetch(`${ENV.Infraestructure_API}/devices/getAllWithoutTopic_csv?searchBy=${JSON.stringify(filters)}`,headers);
      if (!response.ok) throw new Error('Error al descargar el archivo');
      
      // Crear un enlace para descargar el archivo
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error al descargar el CSV:', error);
    }
}
  async processCsv(delimiter: string,serverFileName: string,aditionalData: any = null) {
    const headers = {headers: {"x-access-token": this.token,"Content-Type": "application/json"}};
    const body = JSON.stringify({...aditionalData,delimiter: delimiter,file_name: serverFileName});
    return await axios.post(`${ENV.Infraestructure_API}/devices/process_csv`,body,headers);
  }
}
