import { useEffect, useState } from "react";
import { useAuth } from "../../Hooks";
import { Button,Card,Col, Divider, Row, Select,Skeleton, Tooltip, Typography, } from "antd";
import { useTranslation } from "react-i18next";
import UserService from "../../services/moduleAuth/User/User.service";

const { Text, Title, Paragraph } = Typography;

const MyProfile = () => {
  const { user, logout } = useAuth();
  const userService = new UserService();
  const [usersData, setUsersData] = useState({});
  const [loading, setLoading] = useState(false);
  const [councilDetails, setCouncilDetails] = useState({});

  const getUsersData = async () => {
    try {
      setLoading(true);
      const response = await userService.getById(user?.userId);
      setUsersData(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      return err;
    }
  };
  useEffect(() => {
    getUsersData();
  }, []);

  const { t } = useTranslation();

  return (
    <div style={{ padding: "25px" }}>
      <Title level={2}>{t("MyProfile")}</Title>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col style={{ padding: 8 }} xl={12} span={24}>
          {loading ? (
            <Skeleton active />
          ) : (
            <Card>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={logout}
                  primaryColor="#9c3fb9"
                  type="primary"
                  size="medium"
                >
                  Logout
                </Button>
              </div>
              <Paragraph>
                <Text strong>{t("First Name")}: </Text>
                <Text>{usersData?.name}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>{t("Last Name")}: </Text>
                <Text>{usersData?.surname}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>{t("User Role")}: </Text>
                <Text>{usersData?.userRole}</Text>
              </Paragraph>

              <Divider dashed />

              <Text strong>{t("Address")}: </Text>
              <Text>
                <Tooltip title={t("Country")}>{usersData?.country}</Tooltip> /
                <Tooltip title={t("State")}>
                  {usersData?.state || "N/A"}
                </Tooltip>{" "}
                /
                <Tooltip title={t("City")}>
                  {usersData?.locality || "N/A"}
                </Tooltip>{" "}
                /
                <Tooltip title={t("Street")}>
                  {usersData?.street || "N/A"}
                </Tooltip>
              </Text>
              <Paragraph>
                <Text strong>{t("Postal Code")}: </Text>
                <Text>{usersData?.postalCode}</Text>
              </Paragraph>

              <Divider dashed />

              <Paragraph>
                <Text strong>{t("Phone")}: </Text>
                <Text>{usersData?.phone}</Text>
              </Paragraph>
            </Card>
          )}
        </Col>

        <Col style={{ padding: 8 }} xl={12} span={24}>
          {loading ? (
            <Skeleton active />
          ) : (
            <Card>
              <Title level={2}>{t("Council")}</Title>
              <Select
                placeholder="Select Council"
                style={{
                  width: "100%",
                }}
                options={
                  usersData?.Councils?.map((data) => {
                    return { ...data, value: data?.id, label: data?.name };
                  }) || []
                }
                onChange={(id) => {
                  let selectedCouncil = usersData?.Councils?.find(
                    (data) => data?.id == id,
                  );
                  setCouncilDetails(selectedCouncil || {});
                }}
              />

              {Object.keys(councilDetails)?.length > 0 && (
                <Card title={councilDetails?.name} style={{ marginTop: 16 }}>
                  <Paragraph>
                    <Text strong>{t("Name")}: </Text>
                    <Text>{councilDetails?.name}</Text>
                  </Paragraph>
                  <Paragraph>
                    <Text strong>{t("Web")}: </Text>
                    <Text>{councilDetails?.web}</Text>
                  </Paragraph>

                  <Divider dashed />

                  <Text strong>{t("Address")}: </Text>
                  <Text>
                    <Tooltip title={t("Country")}>
                      {councilDetails?.country}
                    </Tooltip>{" "}
                    /
                    <Tooltip title={t("State")}>
                      {councilDetails?.state || "N/A"}
                    </Tooltip>{" "}
                    /
                    <Tooltip title={t("City")}>
                      {councilDetails?.locality || "N/A"}
                    </Tooltip>{" "}
                    /
                    <Tooltip title={t("Street")}>
                      {councilDetails?.street || "N/A"}
                    </Tooltip>
                  </Text>
                  <Paragraph>
                    <Text strong>{t("Postal Code")}: </Text>
                    <Text>{councilDetails?.postalCode}</Text>
                  </Paragraph>

                  <Divider dashed />

                  <Paragraph>
                    <Text strong>{t("Phone")}: </Text>
                    <Text>{councilDetails?.phone}</Text>
                  </Paragraph>
                </Card>
              )}
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MyProfile;
