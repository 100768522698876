import { ENV } from "../../../utils";
import axios from "axios";
import AuthService from "../../moduleAuth/Auth/Auth.service";

export default class NetworkServerService {
  public cache: Cache | undefined;
  token: string;
  headers: any;

  constructor() {
    this.token = AuthService.getToken();
    this.cache = undefined; 
    this.headers = { "x-access-token": this.token, "Content-Type": "application/json", }
  }
  async getAll(topic: string = "") {
    const headers = {
      headers: this.headers,
    };
    if(!this.cache)
      this.cache =  (await axios.get(`${ENV.Infraestructure_API}/network-servers`, headers)).data;

    return this.cache 

  }
}
