import { ENV } from "../../../utils";
import axios from "axios";

export default class MqttClientService {
  async connect(topic: string) {
    return await axios.post(
      `${ENV.MqttServer_API}/${ENV.API_ROUTES.MQTTCLIENT}/connect/${topic}`,
    );
  }
  async disconnect() {
    return await axios.post(
      `${ENV.MqttServer_API}/${ENV.API_ROUTES.MQTTCLIENT}/disconnect`,
    );
  }
  async publish(payload: any) {
    return await axios.post(
      `${ENV.MqttServer_API}/${ENV.API_ROUTES.MQTTCLIENT}/publish`,
      payload,
    );
  }
  async subscribe(payload: any) {
    return await axios.post(
      `${ENV.MqttServer_API}/${ENV.API_ROUTES.MQTTCLIENT}/subscribe`,
      payload,
    );
  }
}
