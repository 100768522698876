import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Typography } from "antd";
import { BasicApi } from "../../services/general/basicAPI/basicApi";
import { ENV } from "../../utils/index.js";

const { Text } = Typography;

function PhysicalGatewaysTableSelector(props) {
  const element = props.element;
  const dataName = props.dataName;
  const title = props.title;
  const form = props.form;

  const { t } = useTranslation();
  const [physicalGatewaysService] = useState(
    new BasicApi(
      ENV.Infraestructure_API + "/" + ENV.API_ROUTES.PHYSICAL_GATEWAY,
    ),
  );
  // TODO: Cambiar esto por una llamada a todos los NS del gateway a través de api al server
  const [data, setData] = useState([]);

  const onValueChange = (value) => {
    form.setFieldValue(dataName, value);
  };

  // generateFormOptions
  useEffect(() => {
    let result = [];
    physicalGatewaysService.getAll().then((value) => {
      if (value)
        result = value.db.result.map((element) => {
          return { name: element.name, mac: element.mac };
        });
      setData(result);
    });
  }, [physicalGatewaysService]);

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <>
        <Select
          showSearch
          style={{
            width: 200,
          }}
          placeholder={t("Search to Select")}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={data.map((physical_gateway) => ({
            label: physical_gateway.name,
            value: physical_gateway.mac,
          }))}
          onChange={onValueChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </>
    </Form.Item>
  );
}

export default PhysicalGatewaysTableSelector;
