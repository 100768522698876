import { ENV } from "../../../utils";
import axios from "axios";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import { columns } from "./Columns";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";

export default class MqttServerService
  extends AbstractCRUD<any>
{
  columns: IColumnForCRUD[];

  constructor() {
    const token = AuthService.getToken();
    const url = `${ENV.Infraestructure_API}/${ENV.API_ROUTES.MQTTSERVER}`;
    const headers = { "x-access-token": token, "Content-Type": "application/json" };
    super(url, headers); 
    this.columns = columns; 
  }

  getColumns(): IColumnForCRUD[] {
    return this.columns;
  }

  async getEnums(): Promise<any> {
    return await axios.get(`${this.url}/enum`, { headers: this.headers });
  }

}
