// utils
import React from "react";
import { useState } from "react";
import { FullDynamicTable } from "../../../components/DynamicTable";
import { Flex, message, Space } from "antd";
import { EditOutlined, DeleteOutlined,ApartmentOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import ActuatorService from "../../../services/moduleStreetlight/Actuator/Actuator.service";
import { useNavigate } from "react-router-dom";
import ScheduleParentModal from "../../../components/moduleStreetlight/ScheduleParentModal/ScheduleParentModal";
  export default function ActuatorTab({ControlCabinetId}: {  ControlCabinetId: number;}) {
  const navigate = useNavigate();
  const [entityId, setEntityId] = useState<number>(-1);
  const [service] = useState<ActuatorService>(new ActuatorService(ControlCabinetId));
  const [refreshTable, setRefreshTable] = useState<boolean>(true);
  
  //MODALS
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  
  const ModalAddCallback=()=>{
    setEntityId(-1)
    setEditModal(true)
  }
  const columnsWithActions = [
        ...service.getColumns(),
        {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 100,
        align: 'center',
        render: (text:string, record:any) => (
          <Flex justify="center" ><Space align="center">
          <EditOutlined
            style={{ fontSize: "1.8em", color: "green" }}
            onClick={() => {
              setEntityId(record.id);
              setEditModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
          <ApartmentOutlined
            style={{ fontSize: "1.8em", color: "green"  }} 
            onClick={() => {
              navigate(`${location.pathname}/Actuator/${record.id}/stGroup`)
            }} 
            onPointerOverCapture={undefined} onPointerMoveCapture={undefined}
            />

          <DeleteOutlined
            style={{ fontSize: "1.8em", color: "red" }}
            onClick={() => {
              setEntityId(record.id);
              setDeleteModal(true);
            }}
            onPointerOverCapture={undefined}
            onPointerMoveCapture={undefined}
          />
        </Space></Flex>
      ),
    },
  ];
  return (
    <>
      {/* Actuator*/}
       <ScheduleParentModal
        service={service}
        entityId={entityId}
        modalOpen={editModal}
        setModalOpen={setEditModal}
        scheduleParentType="Actuator"
        callback={()=>setRefreshTable(!refreshTable)}
      />
      <ConfirmationModal
        callback={async ()=>{ 
          if(await service.delete(entityId)){
            setRefreshTable(!refreshTable);
            message.success("Deleted successfully!")
          }
        }}
        message="Esta seguro de que quiere eliminar este actuador?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <FullDynamicTable
        title="Actuador"
        service={service}
        columns={columnsWithActions}
        generalActions={
          { 
            add: {replaceForm:true,callback:ModalAddCallback}, 
            deleteSelected: {}
          }
        }
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        multiSelect={true}
      />
    </>
  );
}
