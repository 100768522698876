import ApplicationService from "../Application/Application.service";
import DeviceProfileService from "../DeviceProfile/DeviceProfile.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
export const Columns =  (mac: string): IColumnForCRUD[] => {
    return [
        { dataIndex: 'name', title: 'Name',  type: 'text' },
        { dataIndex: 'devEUI', title: 'devEUI', type: 'text',  },
        { dataIndex: 'description', title: 'description', type: 'text',  },
        { dataIndex: "lastSeen", title: "status", type: 'text',  editable: false },
        { dataIndex: "type", title: "type",  type: 'text' },
        { dataIndex: "isDisabled", title: "isDisabled", type: 'boolean',  },
        { dataIndex: 'referenceAltitude', title: 'referenceAltitude',  type: 'text',  },
        { dataIndex: 'skipFCntCheck', title: 'skipFCntCheck',  type: 'text',  },
        { dataIndex: "applicationID", title: "app_ID",  type: 'service', service: new ApplicationService(mac) },
        { dataIndex: 'deviceProfileID', title: 'deviceProfileID',  type: 'service',service: new DeviceProfileService(mac) },
        { dataIndex: 'tags', title: 'tags',  type: 'text',   defaultValue: "{}", disabled:true },
        { dataIndex: 'variables', title: 'variables',  type: 'text',   defaultValue:"not_available", disabled:true },
    ];
}