import { ENV } from "../../../utils";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";
import { getAllForAlarms, uploadCSV } from "../../interfaces.service";
import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig";
import AuthService from "../../moduleAuth/Auth/Auth.service";
import axios from "axios";
import { Columns } from "./Columns";

export default class GatewayService  extends AbstractCRUD<any> implements getAllForAlarms, uploadCSV
{
  topic?: string;
  uploadEndpoint: string;

  constructor(topic: string | undefined = undefined) {
    const token = AuthService.getToken();
    const url = `${ENV.Infraestructure_API}/${topic}/gateways`;
    const headers = { "x-access-token": token, "Content-Type": "application/json" };
    super(url, headers); // Inicializa BaseService con URL y headers
    this.topic = topic;
    this.uploadEndpoint = `${ENV.Infraestructure_API}/gateways/upload`;
  }

  getColumns(): IColumnForCRUD[] {
    return Columns();
  }

  async getAllForAlarms(filters: any = {}, currentPage: number = 1, pageSize: number = 10): Promise<any> {
    const offset = (currentPage - 1) * pageSize;
    const response = await axios.get(
      `${ENV.Infraestructure_API}/gatewaysForAlarms?filters=${JSON.stringify(filters)}&limit=${pageSize}&offset=${offset}`,
      { headers: this.headers }
    );
    return response.data;
  }

  async processCsv(delimiter: any, serverFileName: any, aditionalData: any = null): Promise<any> {
    const body = JSON.stringify({ ...aditionalData, delimiter, file_name: serverFileName });
    return await axios.post(`${ENV.Infraestructure_API}/gateways/process_csv`, body, { headers: this.headers });
  }

  async getEnums(): Promise<any> {
    return {};
  }
}
