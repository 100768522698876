import React from 'react';
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button } from 'antd';

interface ButtonProps {
    onClick?: () => void;
}
const ScheduleButton: React.FC<ButtonProps> = ({ onClick}) => {
    return <Button
    style={{ margin: "6px", color: "white" }}
    className="buttonCentered"
    icon={
      <ClockCircleOutlined
        onPointerOverCapture={undefined}
        onPointerMoveCapture={undefined}
      />
    }
    onClick={onClick}
  />
};

export default ScheduleButton;