import { useState } from "react";
import { FullDynamicTable } from "../../components/DynamicTable"
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { Flex, message } from "antd";
import AddOrEditEntityModal from "../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import GatewayService from "../../services/moduleLora/Gateway/Gateway.service";
import { CRUDPrivilages } from "../../components/DynamicTable/Interfaces/CRUDPrivilages";


//import ControlCabinetTab from "./ControlCabinetTab/ControlCabinetTab";

type params = { 
    topic: string 
    CRUDPrivilages: CRUDPrivilages|undefined
}

export default function GatewayPage({topic="",CRUDPrivilages = undefined}:params){
    const service = new GatewayService(topic)
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [entityId, setEntityId] = useState(0);
    const [refreshTable, setRefreshTable] = useState(true);
    const columns = [
      ...service.getColumns(),
      {
        title: <Flex align="center" justify="center">Actions</Flex>,
        key: 'actions',fixed: 'right',  width: 230,
        align: 'center',
        render: (text:string, record:any) => (
        <>
            <EditOutlined 
                style={{ fontSize: "large", color: "#7150CC" }} 
                onClick={() => {setEntityId(record.id);setEditModal(true);}} 
                onPointerOverCapture={undefined} onPointerMoveCapture={undefined}              
            />
            <DeleteOutlined 
                style={{ fontSize: "large", color: "#DC4446" }} 
                onClick={() => {setEntityId(record.id);setDeleteModal(true);} } 
                onPointerOverCapture={undefined} onPointerMoveCapture={undefined}
            />
        </>
        )
        }
    ]
    return (<>
           <AddOrEditEntityModal 
          title="Gateway"
          type="edit"
          service={service} id={entityId} 
          isModalOpen={editModal} setIsModalOpen={(setEditModal)}
          paramsPerPage={10}
          callback={()=>{setRefreshTable(!refreshTable)}}
          />   

          <ConfirmationModal
          callback={async ()=>{ 
            if(await service.delete(entityId)){
              setRefreshTable(!refreshTable);
              message.success("Deleted successfully!")
            }
          }}
          message= "Are you sure you want to delete this Gateway?"
          modalOpen={deleteModal}
          setModalOpen={setDeleteModal}
          />

          <FullDynamicTable 
          title="Gateway"
          service={service}
          generalActions={            
            {
              add:{},
              //csv:{csvOptions:{},fileExtension:".csv"},
              configColumns:{},
              deleteSelected:{}
            }
          }
          columns={columns}
          multiSelect={true}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
          CRUDPrivilage={CRUDPrivilages}
          />
        </>)
}