import React, { useState, useEffect } from "react";
import { message, Row, Col, Button } from "antd";
import { Checkbox } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import dayjs from "dayjs";
import { ModalAlarms } from "./ModalAlarms/ModalAlarms";
import { ModalDelete } from "../../../components/ModalDelete/ModalDelete";
import AlarmService from "../../../services/moduleAlarms/Alarms/Alarms.service";
import { HardwareTypes } from "../../../services/moduleAlarms/Alarms/Alarm.interfaces";
import { AlarmOptionType } from "./ModalAlarms/AlarmSetting/AlarmSetting";

type params = { hardwares: HardwareTypes[],hardwareServices?:any,optionTypes?:AlarmOptionType };

function Alarms({ hardwares,hardwareServices,optionTypes={"connection":{}} }: params) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [alarmsData, setAlarmsData] = useState<any>({
    result: [],
    totalCount: 0,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [alarmEntity, setAlarmEntity] = useState<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [hardwareSelectedKeys, setHardwareSelectedKeys] = useState<any>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [alarmService] = useState(new AlarmService(hardwares));
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  function openDeleteModal(entity: any) {
    setAlarmEntity(entity);
    setIsDeleteModalOpen(true);
  }

  const deleteAlarm = async () => {
    await alarmService.delete(alarmEntity.id);
    setRefreshTable(!refreshTable);
    setIsDeleteModalOpen(false);
  };

  const ModalUpdate = async (alarm: any) => {
    setAlarmEntity({ ...alarm, start_date: dayjs(alarm.start_date) });
    setHardwareSelectedKeys(alarm.hardwares);
    setCurrentStep(0);
    setModalOpen(true);
  };
  const ModalCreate = async () => {
    setAlarmEntity({
      id: undefined,
      name: "",
      description: "",
      deactive_hours: 3,
      start_date: dayjs(),
      enable: true,
      emails: [],
      email_subject: "",
      email_body: "",
      alarm_status: "GENERATED",
      gateways: [],
      devices: [],
      stas: [],
      alarmType: "connection",
      variableName: "",
      variableValue: 0,
    });
    let tmp: any = {};
    hardwares.map((item) => (tmp[item] = []));
    setHardwareSelectedKeys(tmp);
    setCurrentStep(0);
    setModalOpen(true);
  };

  useEffect(() => {
    alarmService.getAlarms({},currentPage,pageSize).then((data) => {
      setAlarmsData(data)
      let tmp: any = {};
      setHardwareSelectedKeys(hardwares.map((item) => (tmp[item] = [])));
    });
  }, [hardwares, refreshTable, alarmService]);

  return (
    <>
      <ModalDelete
        originalData={alarmEntity ? alarmEntity : {}}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        deleteFunction={deleteAlarm}
      />

      {/*MODAL*/}
      <ModalAlarms
        alarmId={alarmEntity ? alarmEntity.id : undefined}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        currentStep={currentStep}
        onChangeStep={setCurrentStep}
        alarmEntity={alarmEntity}
        setAlarmEntity={setAlarmEntity}
        hardwares={hardwares}
        hardwareServices={hardwareServices}
        hardwareSelectedKeys={hardwareSelectedKeys}
        setHardwareSelectedKeys={setHardwareSelectedKeys}
        callback={() => setRefreshTable(!refreshTable)}
        optionTypes={optionTypes}
      />
      {contextHolder}

      <Row align="middle" justify="space-between">
        <Col></Col>
        <Col style={{ padding: "1rem" }}>
          <Button
            type="primary"
            className="buttonCentered"
            icon={
              <PlusOutlined
                onPointerOverCapture={undefined}
                onPointerMoveCapture={undefined}
              />
            }
            onClick={ModalCreate}
          />
        </Col>
      </Row>
      <Table
        dataSource={alarmsData.result || []}
        rowKey="id"
        scroll={{ y: 350 }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: alarmsData.totalCount,
          onChange: (page, limit) => {
            setCurrentPage(page);
            setPageSize(limit);
            setRefreshTable(!refreshTable);
          },
        }}
      >
        <Table.Column
          title={t("Name")}
          dataIndex="name"
          key="name"
          width={"100px"}
        />
        <Table.Column
          title={t("Description")}
          dataIndex="description"
          key="description"
          width={"300px"}
        />

        <Table.Column
          title={t("Start_date")}
          dataIndex="start_date"
          key="date"
          width={"100px"}
        />
        <Table.Column
          title={t("Deactive hours")}
          dataIndex="deactive_hours"
          key="time"
          width={"100px"}
        />
        <Table.Column
          title={t("Emails")}
          render={(text, record: any) => {
            record.emails =
              typeof record.emails === "string"
                ? JSON.parse(record.emails)
                : record.emails;
            return record.emails.map((item: string) => item.trim()).join(", ");
          }}
        />
        <Table.Column
          title={t("Enable")}
          dataIndex="enable"
          key="time"
          width={"100px"}
          render={(text, record: any) => {
            return (
              <Checkbox
                checked={record.enable}
                onClick={async (value) => {
                  await alarmService.update(record.id, {
                    enable: !record.enable,
                  });
                  setRefreshTable((prev)=>!prev);
                }}
              />
            );
          }}
        />
        <Table.Column
          title={t("Actions")}
          key="action"
          render={(text, record) => (
            <>
              <EditOutlined
                style={{ fontSize: "large" }}
                onClick={() => {
                  ModalUpdate(record);
                }}
                onPointerOverCapture={undefined}
                onPointerMoveCapture={undefined}
              />
              <DeleteOutlined
                onClick={() => {
                  openDeleteModal(record);
                }}
                style={{ fontSize: "large" }}
                onPointerOverCapture={undefined}
                onPointerMoveCapture={undefined}
              />
            </>
          )}
        />
      </Table>
    </>
  );
}
export default Alarms;
