import { ENV } from "../../../utils";
import { AbstractCRUD } from "../../general/AbstractCRUD/AbstractCRUD";
import { Columns } from "./Columns";
import AuthService from "../../moduleAuth/Auth/Auth.service";

export default class ServiceProfileService extends AbstractCRUD<any> {
  mac?: string;
  columns: any;
  organization: string | undefined;

  constructor(mac: string | undefined = undefined, organization:string | undefined = undefined,) {
    const token = AuthService.getToken();
    const url = `${ENV.Infraestructure_API}/${mac}/service-profiles`;
    const headers = { "x-access-token": token, "Content-Type": "application/json" };
    super(url, headers);
    this.mac = mac;
    this.organization = organization;
    this.columns = Columns(this.mac as string,this.organization as string);
  }

  getColumns(): any {
    return this.columns;
  }

  async getEnums(): Promise<any> {
    return {};
  }
}
