import { useEffect, useState } from "react";
import { FullDynamicTable } from "../../components/DynamicTable";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Flex, message, Space } from "antd";
import AddOrEditEntityModal from "../../components/AddOrEditEntityModal/AddOrEditEntityModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { CRUDPrivilages } from "../../components/DynamicTable/Interfaces/CRUDPrivilages";
import DeviceProfileService from "../../services/moduleLora/DeviceProfile/DeviceProfile.service";
import { useParams } from "react-router-dom";

type params = { CRUDPrivilages: CRUDPrivilages };
export default function DeviceProfile({ CRUDPrivilages }: params) {
  const { physicalGatewayId, organizationId } = useParams();
  console.log(physicalGatewayId)
  const deviceProfileService = new DeviceProfileService(physicalGatewayId, organizationId )

  const [service, setService] = useState(deviceProfileService);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deviceProfileId, setDeviceProfileId] = useState(0);
  const [refreshTable, setRefreshTable] = useState(true);

  useEffect(() => {
    setService(service);
  }, []);

  const columns = [
    ...service.getColumns(),
    {
      title: (
        <Flex align="center" justify="center">
          Actions
        </Flex>
      ),
      key: "actions",
      dataIndex:"actions",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text: string, record: any) => (
        <Flex justify="center">
          <Space align="center">
            {CRUDPrivilages.update && (
              <EditOutlined
                style={{ fontSize: "1.8em", color: "#7150CC" }}
                onClick={() => {
                  setDeviceProfileId(record.id);
                  setEditModal(true);
                }}
              />
            )}
            {CRUDPrivilages.delete && (
              <DeleteOutlined
                style={{ fontSize: "1.8em", color: "#DC4446" }}
                onClick={() => {
                  setDeviceProfileId(record.id);
                  setDeleteModal(true);
                }}
              />
            )}
          </Space>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <AddOrEditEntityModal
        title={`Device profile `}
        type="edit"
        service={service}
        id={deviceProfileId}
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        paramsPerPage={10}
        callback={() => {
          setRefreshTable(!refreshTable);
        }}
      />

      <ConfirmationModal
        callback={async ()=>{ 
        if(await service.delete(deviceProfileId)){
          setRefreshTable(!refreshTable);
          message.success("Deleted successfully!")
          }
        }}
        message="Are you sure you want to delete this device profile?"
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
      />

      <FullDynamicTable
        title={`Device Profile`}
        service={service}
        generalActions={{
          add: CRUDPrivilages.create ? {} : null,
          configColumns: {},
        }}
        columns={columns}
        multiSelect={true}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
      />
    </>
  );
}
