import { IColumnForCRUD } from '../../../components/DynamicTable/Interfaces/ColumnConfig';
import CountryService from '../../general/Geo/Countries.service';

export const UserColumns:IColumnForCRUD [] = [
    {dataIndex: 'username',title: 'Username',type: 'text'},
    {dataIndex: 'name',title: 'Name',type: 'text'},
    {dataIndex: 'surname',title: 'Surname',type: 'text'},
    {dataIndex: 'roleName',title: 'Role',type: 'roleSelector'},
    {dataIndex: 'email',title: 'Email',type: 'text'},
    {dataIndex: 'country',title: 'Country',type: 'service',service: new CountryService()},
    {dataIndex: 'postalCode',title: 'Postal Code',type: 'text'},
    {dataIndex: 'city',title: 'City',type: 'text'},
    {dataIndex: 'phone',title: 'Phone',type: 'text'},
    {dataIndex: 'pswd',title: 'Password',type: 'text'}];