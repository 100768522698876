import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Input } from "antd";

import  {
  HardwareTypes,
} from "../../../../../services/moduleAlarms/Alarms/Alarm.interfaces";
import AlarmService from "../../../../../services/moduleAlarms/Alarms/Alarms.service";
type params = {
  alarmService: AlarmService;
  selectedKeys: any;
  setSelectedKeys: Function;
  hardware: HardwareTypes;
  hardwareService:any;
};
export const TableHardware = ({
  selectedKeys,
  setSelectedKeys,
  alarmService,
  hardware,
  hardwareService,
}: params) => {
  const [filters, setFilters] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  useEffect(() => {
    hardwareService.getAllForAlarms(filters, currentPage, pageSize)
      .then((data:any) => {
        if (data.result?.length) {
          setColumns(
              Object.keys(data.result[0])
                .filter((key)=>!["value"].includes(key))
                .map((key) => ({
                  title:  <>
                            {key=="topic"? "gateway mac":key.replace(/__/g, ".")}
                            <Input onChange={async (value) => {
                              let test= {...filters,[key]:value.target.value}
                              let result = await hardwareService
                                .getAllForAlarms(test, currentPage, pageSize)
                              setFilters({...filters,[key]:value.target.value})
                              setData(result)
                            }}/>
                          </>,
                    dataIndex: key,
                  key: key
                }))
          );
        }
      })
  }, []);

  useEffect(() => {
    hardwareService.getAllForAlarms(filters, currentPage, pageSize)
      .then((data:any) => {
        if (data.result?.length) setData(data)
      })
  }, [hardware, alarmService,refreshTable]);
  if (!selectedKeys[hardware])
    return <> The value of selectedKeys[{hardware}] is undefined</>;
  
  return (
    <Table
      dataSource={data.result}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: data.totalCount,
        onChange: (page, limit) => {
          setCurrentPage(page);
          setPageSize(limit);
          setRefreshTable(!refreshTable);
        },
      }}
      columns={columns}
      scroll={{ y: 350 }}
      rowKey={(record) => record.value}
      rowSelection={{
        selectedRowKeys: selectedKeys[hardware],
        type: "checkbox",
        fixed: true,
        onSelectAll: async (selected:any, selectedRows:any, changeRows:any) => {
          if(selected && hardwareService.getAllForAlarms){
            let values = (await hardwareService.getAllForAlarms(filters, 0, 0)).result.map((item:any) => item.value)
            setSelectedKeys({...selectedKeys,[hardware]: values});
          }else{
            setSelectedKeys({...selectedKeys,[hardware]: []});
            }
        },


        onSelect: (record: any, selected) => {
          if (selected) {
            setSelectedKeys({...selectedKeys,[hardware]: [...selectedKeys[hardware], record.value]});
          } else {
            setSelectedKeys({
              ...selectedKeys,
              [hardware]: [
                ...selectedKeys[hardware].slice(0,selectedKeys[hardware].indexOf(record.value)),
                ...selectedKeys[hardware].slice(selectedKeys[hardware].indexOf(record.value) + 1),
              ],
            });
          }
        }
      }}
    />
  );
};
