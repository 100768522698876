import { IColumnForCRUD } from "../../../components/DynamicTable/Interfaces/ColumnConfig"
import NetworkServerService from "../NetworkServer/NetworkServer.service";
import OrganizationService from "../Organization/Organization.service";

export const Columns = (mac: string,organization: string): IColumnForCRUD[] => {
    return [{ dataIndex: 'topic', title: 'NS', type: 'text',  editable: false },
    { dataIndex: 'name', title: 'Name', type: 'text' },
    { dataIndex: 'networkServerID', title: 'Network Server ID', type: 'service', service: new NetworkServerService(),defaultValue: mac, serviceLabel: "gatewayMac", disabled:true },
    { dataIndex: 'organizationID', title: 'Organization ID', type: 'service', service: new OrganizationService(mac), defaultValue: organization, disabled:true , serviceLabel: "name", serviceValue: "id" },
    { dataIndex: 'uplinkInterval', title: 'UplinkInterval', type: 'text', visible: false },
    { dataIndex: 'factoryPresetFreqs', title: 'Freq. (should be [0])', type: 'text', },
    { dataIndex: 'adrAlgorithmID', title: 'adrAlgorithmID', type: 'text', visible: false },
    { dataIndex: 'classBTimeout', title: 'classBTimeout', type: 'text', visible: false },
    { dataIndex: 'classCTimeout', title: 'classCTimeout', type: 'text', visible: false },
    { dataIndex: 'geolocBufferTTL', title: 'geolocBufferTTL', type: 'text', visible: false },
    { dataIndex: 'geolocMinBufferSize', title: 'geolocMinBufferSize', type: 'text', visible: false },
    { dataIndex: 'macVersion', title: 'macVersion', type: 'text', visible: false },
    { dataIndex: 'maxDutyCycle', title: 'maxDutyCycle', type: 'text', visible: false },
    { dataIndex: 'maxEIRP', title: 'maxEIRP', type: 'text', visible: false },
    { dataIndex: 'payloadCodec', title: 'payloadCodec', type: 'text',  editable: false },
    { dataIndex: 'payloadDecoderScript', title: 'payloadDecoderScript', type: 'text', visible: false },
    { dataIndex: 'payloadEncoderScript', title: 'payloadEncoderScript', type: 'text', visible: false },
    { dataIndex: 'pingSlotDR', title: 'pingSlotDR', type: 'text', visible: false },
    { dataIndex: 'pingSlotFreq', title: 'pingSlotFreq', type: 'text', visible: false },
    { dataIndex: 'pingSlotPeriod', title: 'pingSlotPeriod', type: 'text', visible: false },
    { dataIndex: 'regParamsRevision', title: 'regParamsRevision', type: 'text', visible: false },
    { dataIndex: 'rfRegion', title: 'rfRegion', type: 'text', visible: false },
    { dataIndex: 'rxDROffset1', title: 'rxDROffset1', type: 'text', visible: false },
    { dataIndex: 'rxDataRate2', title: 'rxDataRate2', type: 'text', visible: false },
    { dataIndex: 'rxDelay1', title: 'rxDelay1', type: 'text', visible: false },
    { dataIndex: 'rxFreq2', title: 'rxFreq2', type: 'text', visible: false },
    { dataIndex: 'supports32BitFCnt', title: 'supports32BitFCnt', type: 'text', visible: false },
    { dataIndex: 'supportsClassB', title: 'supportsClassB', type: 'text', visible: false },
    { dataIndex: 'supportsClassC', title: 'supportsClassC', type: 'text', visible: false },
    { dataIndex: 'supportsJoin', title: 'supportsJoin', type: 'text', visible: false },
    { dataIndex: 'tags', title: 'tags', type: 'text', visible: false },
    ]
}
