import { useState, useEffect } from "react";
import { Button, Flex, Input, InputNumber, Select, Table, Typography } from "antd";
import { PlusOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";

import AddOrEditEntityModal from "../../AddOrEditEntityModal/AddOrEditEntityModal";
import { ButtonUploadCSV } from "../../csvUpload/ButtonCsvUpload";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { IColumnForCRUD } from "../Interfaces/ColumnConfig";
import { FullDynamicTableParams } from "./params";
import LastSeenFilterDropdown from "../LastSeenColumn/LastSeenFilterDropdown";
import LastSeenRender from "../LastSeenColumn/LastSeenRender";
import ConfigColumns from "../ConfigColumns/ConfigColumns";

const FullDynamicTable: React.FC<FullDynamicTableParams> = ({
  title,
  service,
  generalActions = {
    add: {
      replaceForm:false,
      callback:()=>{}
    },
    csv: {
      service: service,
      callback: () => { },
      description: "'ID PLATAFORMA', 'ID device',	'TIPO', 'LOCALIZACIÓN'",
      children: () => {
        return <></>;
      },
      options: null,
      fileExtension: ".csv",
    },
    configColumns: {},
    deleteSelected: {},
  },
  extraGeneralActions = <></>,
  columns,
  multiSelect = false,
  refreshTable,
  setRefreshTable,
  CRUDPrivilage = { create: true, update: true, delete: true },
  replaceGetAll = undefined,
  apiBasedSearch = false,
  callbackSelected = ()=>{}
})=> {
  // limit and page
  const { Title } = Typography;
  const [disableLastSeen, setDisableLastSeen] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<any>({});
  const [enums, setEnums] = useState<any>({});
  const [columnsToDisplay, setColumnsToDisplay] = useState<any>([]);
  const [editableColumns, setEditableColumns] = useState<IColumnForCRUD[]>(columns);
  // const [disableLastSeen, setDisableLastSeen] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState({});
  // columns values
  const [dataSource, setDataSource] = useState<{data: any[];totalCount: number;}>({ data: [], totalCount: 0 });
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | number[]>([]);
  //Modals
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const rowSelection = {
    selectedRowKeys,
    onSelectAll: async (selected: any, selectedRows: any, changeRows: any) => {
      selected && service.getAllIds
        ? setSelectedRowKeys(
          (await service.getAllIds()).map((item: any) => item.value)
        )
        : setSelectedRowKeys([]);
    },
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  //each time the filters change, the pagination is reset
  useEffect(() => {
    setCurrentPage(1);
    
  }, [filters]);

  //pass selectedRowKeys to callbackSelected
  useEffect(()=>{
    callbackSelected(selectedRowKeys)
  },[selectedRowKeys])

  //get data
  useEffect(() => {
    if (Object.keys(searchFilters).length === 0) {
      const tmp_service = service as any;
      const functionToCall = replaceGetAll ? replaceGetAll : "getAll";
      tmp_service[functionToCall](filters, currentPage, pageSize)
      .then((data: any) => {
        setDataSource(data)
      })
      .catch((error: any) => {
        console.log("error", error);
        setDataSource({ data: [], totalCount: 0 });
      });
    }
  }, [filters, refreshTable, currentPage, service]);

  // get enums
  useEffect(() => {
    service
      .getEnums()
      .then((data: any) => setEnums(data))
      .catch((error: any) => console.log("error", error));
  }, []);

  // get enums
  useEffect(() => {
    service
      .getEnums()
      .then((data: any) => setEnums(data))
      .catch((error: any) => console.log("error", error));
  }, [service]);

  const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    // setPagination(pagination);
    setSearchFilters(filters);
    onSearch(filters);
  };

  const onSearch = async (filters: any) => {
    if (apiBasedSearch) {
      await getData(5, 0, filters);
    }
  };

  const getData = async (limit: any, offset: any, filters: any = {}) => {
    try {
      const tmp_service = service as any;
      const functionToCall = replaceGetAll ? replaceGetAll : "getAll";
      tmp_service[functionToCall](filters, currentPage, pageSize)
        .then((data: any) => setDataSource(data))
        .catch((error: any) => {
          console.log("error", error);
          setDataSource({ data: [], totalCount: 0 });
        });
    } catch (error) {
      console.log("Error getting filter data:", error);
    };
  }

  useEffect(() => {
    setColumnsToDisplay(
      editableColumns
        .filter((item: any) =>!Object.keys(item).includes("visible") || item.visible === true)
        .map((item: any) => {
          if (item.title === "actions" || !item.type) return item;
          let inputType = <></>;
          //let render = (text, record) => {<> {record[item.dataIndex]}</>}
          let result = { ...item };
          switch (item.type) {
            case "enum":
              inputType = (
                <Select
                  mode="multiple"
                  style={{ width: "150px" }}
                  onChange={(value) => {setFilters({ ...filters, [item.dataIndex]: value })}}
                  options={enums[item.subtype]?.map((item: any) => {
                    return { label: item, value: item };
                  })}
                />
              );
              break;
              case "text":
                result.filterDropdown = ({ setSelectedKeys, selectedKeys,confirm, clearFilters, close, }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input  placeholder={`Search ${item.title}`} value={selectedKeys[0]}
                      onChange={(e) => {
                        let tmp = { ...filters };
                        e.target.value === "" ? delete tmp[item.dataIndex] : (tmp[item.dataIndex] = e.target.value);
                        setFilters(tmp);
                      }}
                      style={{ marginBottom: 8, display: "block" }}
                    />
                  </div>
                );
  
                result.filterIcon = (filtered: any) => (
                  <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                    rev={undefined}
                  />
                );
                break;
            case "number":
            case "float":
              result.filterDropdown  = (
                <Flex vertical>
                  <Flex style={{margin:"8px"}}>
                    <p style={{margin:"8px"}}> Min:  </p>
                    <InputNumber
                      style={{ width: "150px" }}
                      aria-label="Min:"
                      //value= {filters[`${item.dataIndex}-min`]}
                        onChange={(value: string | null) => {
                          let tmp = {...filters}
                          value === null ? delete tmp[`${item.dataIndex}-min`]: tmp[`${item.dataIndex}-min`] = value
                          setFilters(tmp)
                      }}
                    />
                  </Flex>
                  <Flex style={{margin:"8px"}}>
                  <p style={{margin:"8px"}}> Max:  </p>
                    <InputNumber
                      style={{ width: "150px" }}
                      //value= {filters[`${item.dataIndex}-max`]}
                      onChange={(value) => {
                        let tmp = {...filters}
                        value === null ? delete tmp[`${item.dataIndex}-max`]: tmp[`${item.dataIndex}-max`] = value
                        setFilters(tmp)
                      }}
                    />
                  </Flex>
                </Flex>
              );
              result.filterIcon = (filtered: any) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : undefined }}
                  rev={undefined}
                />
              );

              break;
            case "date":
              inputType = (
                <Flex vertical>
                  <Flex>
                    Min:
                    <Input
                      style={{ width: "150px" }}
                      //value={filters[`${item.dataIndex}-min`]||""}
                      onChange={(e) => {
                        // let value = e.target.value
                        // if (![1,2].includes(value.split("T").length))
                        //     return null
                        // value = value.replace(/[^0-9T:,\-]/g, '')
                        setFilters({
                          ...filters,
                          [`${item.dataIndex}-min`]: e.target.value,
                        });
                      }}
                    />
                  </Flex>
                  <Flex>
                    Max:
                    <Input
                      style={{ width: "150px" }}
                      //value={filters[`${item.dataIndex}-min`]||""}
                      onChange={(e) => {
                        //let value = e.target.value
                        //if (![1,2].includes(value.split("T").length))
                        //    return null
                        //value = value.replace(/[^0-9T:,\-]/g, '')

                        setFilters({
                          ...filters,
                          [`${item.dataIndex}-max`]: e.target.value,
                        });
                      }}
                    />
                  </Flex>
                  <Flex> YYYY-MM-DDThh:mm:ss </Flex>
                </Flex>
              );
              break;
            case "boolean":
              result.filterDropdown = (
                <Select
                  mode="multiple"
                  style={{ width: "150px" }}
                  onChange={(value) => {
                    setFilters({ ...filters, [item.dataIndex]: value });
                  }}
                  options={[
                    { label: "True", value: true },
                    { label: "False", value: false },
                  ]}
                />
              );
              result.filterIcon = (filtered: any) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : undefined }}
                  rev={undefined}
                />
              );
              result.render = (text: string, record: any) => {
                return record[item.dataIndex] ? "True" : "False";
              };
              break;
            case "lastSeen":
              result.filterDropdown = ({ setSelectedKeys, selectedKeys,confirm, clearFilters, close, }: any) => (
                <LastSeenFilterDropdown
                setDisableLastSeen={setDisableLastSeen}
                setFilters={setFilters} 
                filters={filters}
                item={item}
                disableLastSeen={disableLastSeen}
                />
                
              );
              result.filterIcon = (filtered: any) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : undefined }}
                  rev={undefined}
                />
              );

              result.render = (text: string, record: any) => {
                <LastSeenRender text={text} record={record} filters={filters} dataIndex={item.dataIndex} />
              };
              break;
          }
          result.width = 200;
          result.title = (
            <Flex vertical>
              <Flex>
                {result.title[0].toUpperCase() + result.title.slice(1)}{" "}
              </Flex>
              {inputType}
            </Flex>
          );
          return result;
        }),
    );
  }, [enums, editableColumns, filters]);

  return (
    <Flex vertical>
      <Flex justify="space-between">
        <Flex>{title ? <Title>{title as string}</Title> : <> </>}</Flex>
        <Flex justify="right">
          {extraGeneralActions}
          {Object.keys(generalActions).includes("deleteSelected") &&
            CRUDPrivilage.delete && (
              <Button
                style={{ margin: "6px", color: "red" }}
                className="buttonCentered"
                icon={
                  <DeleteOutlined
                    onPointerOverCapture={undefined}
                    onPointerMoveCapture={undefined}
                  />
                }
                onClick={() => {
                  if (selectedRowKeys.length === 0) return;
                  setModalOpenDelete(true);
                }}
              />
            )}

          {Object.keys(generalActions).includes("configColumns") && (
            <ConfigColumns
              allAttributes_raw={editableColumns}
              callback={setEditableColumns}
              disabled={!Object.keys(generalActions).includes("configColumns")}
            />
          )}

          {Object.keys(generalActions).includes("add") &&
            CRUDPrivilage.create && (
              <Button
                type="primary"
                style={{ margin: "6px" }}
                className="buttonCentered"
                icon={
                  <PlusOutlined
                    onPointerOverCapture={undefined}
                    onPointerMoveCapture={undefined}
                  />
                }
                onClick={() => {
                  if(Object.keys(generalActions).includes("add") && generalActions.add?.replaceForm=== true ){
                    generalActions.add?.callback()
                  }else{
                    setModalOpenAdd(true)
                  }
                  
                }
              }
                disabled={!Object.keys(generalActions).includes("add")}
              />
            )}

          {Object.keys(generalActions).includes("csv") && (
            <ButtonUploadCSV
              csvOptions={{
                service: generalActions.csv.service,
                callback: generalActions.csv.callback,
                description: generalActions.csv.description,
                children: generalActions.csv.children,
                options: generalActions.csv.options,
              }}
              fileExtension={generalActions.csv.fileExtension}
            />
          )}
        </Flex>
      </Flex>
      <div
        style={{
          width: "100%",
          height: "5px",
          border: "none",
          backgroundColor: "violet", //"#66ff61"
        }}
      />
      <ConfirmationModal
        callback={async () => {
          if (service && service.delete) await service.delete(selectedRowKeys);
          setRefreshTable(!refreshTable);
        }}
        message={`Are you sure you want to delete ${selectedRowKeys.length} elements?`}
        modalOpen={modalOpenDelete}
        setModalOpen={setModalOpenDelete}
      />

      <AddOrEditEntityModal
        title={(title as string) || ""}
        type="add"
        service={service}
        isModalOpen={modalOpenAdd}
        setIsModalOpen={setModalOpenAdd}
        paramsPerPage={10}
        callback={() => {
          setRefreshTable(!refreshTable);
        }}
      />
      <Table
        scroll={{ x: '20%', y: '65vh' }} 
        dataSource={dataSource?.data}
        columns={columnsToDisplay}
        rowSelection={
          multiSelect ? { type: "checkbox", ...rowSelection } : undefined
        }
        onChange={onTableChange}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: dataSource?.totalCount,
          onChange: (page, limit) => {
            setCurrentPage(page);
            setPageSize(limit);
            setRefreshTable(!refreshTable);
          },
        }}
      />
    </Flex>
  );
}

export default FullDynamicTable